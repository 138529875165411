import React from 'react';
import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

//import logo from '../../assets/images/logo.png';

import styles  from './navbar.module.css';




/**
 * The Nav Bar seen on all components
 * The elements are truncated on mobile
 * The content depends on whether the user is logged in or not
 */


function getAvatar(discordId,avatar,discriminator){
  if(!avatar) return "https://cdn.discordapp.com/embed/avatars/" + discriminator%5 +".png"
  return "https://cdn.discordapp.com/avatars/" +discordId +"/" + avatar + ".png"
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '1920px',

},
  dropDown: {
    color:'#9C9C9C',
    fontFamily:'inherit'
  },
  appBar: {
    background: "transparent",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: '15px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '100px',
      width: 500,
    },
  },
  arrow:{
    color: 'white'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    fontFamily: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  button:{
    fontFamily: 'inherit',
    textTransform: 'capitalize',
    fontWeight: 'lighter',
    fontSize:'20px'
  }
}));

export default function Navbar(props) {
  const classes = useStyles();
  const history = useHistory();

  const {user} = props;
  
  const {discriminator, avatar, discordId, isLoggedIn} = user;

  const handleClickHome = () => {
    history.push("/");
  }

  const handleClickManage = () => {
    history.push("/manage");
  }

  const handleClickBrowse = () => {
    history.push("/browse");
  }

  const handleMembership = () => {
    history.push("/membership");
  }

  const handleSettings = () => {
    history.push("/settings");
  }

  //State for popup menu on mobile
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

//On enter, redirect to browse and display search results
  const keyPress = (e) => {
    if(e.key === 'Enter'){
      if(props.onBrowse)
        props.setSearch(e.target.value)
      else{
        history.push({
          pathname: '/browse',
          state: { search: e.target.value }
        })
      }
    }
 }

  // Authenticate using via passport api in the backend
  // Upon successful login, a cookie session will be stored in the client
  const _handleSignInClick = () => {
    window.open(process.env.REACT_APP_API_ENDPOINT +"auth/discord", "_self");
  };

  // Logout using Discord passport api
  const _handleLogoutClick = async() => {
    try{
      window.open(process.env.REACT_APP_API_ENDPOINT +"auth/logout", "_self");
      localStorage.clear();
    }
    catch(err){
     
    }
  };

  //Mobile Menu Handling, this is from material ui
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      disableScrollLock={true}
    >
      <MenuItem onClick={handleClickHome}>
        <p>Home</p>
      </MenuItem>
      <MenuItem onClick={handleClickBrowse}>
        <p >Browse</p>
      </MenuItem>
      {isLoggedIn ?
          <div>
            <MenuItem onClick = {handleClickManage}>
              <p >Manage Listings</p>
            </MenuItem>
            <MenuItem onClick = {handleMembership}>
              <p>Membership</p>
            </MenuItem>
            <MenuItem onClick = {handleSettings}>
              <p>Settings</p>
            </MenuItem>
            <MenuItem  onClick = { ()=>{_handleLogoutClick()}}>
              <p>Log Out</p>
            </MenuItem>
          </div>
          :
          <MenuItem  onClick = {() =>{_handleSignInClick()}}>
            <p>Log In</p>
          </MenuItem>

      }
    </Menu>
  );
      
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
        elevation={0}
      >
        <Toolbar>
          {window.innerWidth > 599 ? (
            <img onClick={handleClickHome} className = {styles.smallLogo} src ='/images/SmallLogo.png' alt=""/>
            ):null
          }
          { !props.onHome ?
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search Listings"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onKeyPress={keyPress}
            />
          </div>
          : null
          }

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button color="inherit" className={classes.button} onClick={handleClickHome}>Home</Button>         
            <Button color="inherit"  className={classes.button} onClick={handleClickBrowse}>Browse</Button>
            { isLoggedIn ?
              <div className = {styles.dropdown}>
                <Button className = {styles.dropbtn} color="inherit" ><div className = {styles.logOut}><img className={styles.smallPfp} src={getAvatar(discordId,avatar,discriminator)}  alt="Avatar" /></div></Button>
                <div className={styles.dropdownContent}>
                  <MenuItem  className = { classes.dropDown } onClick = {handleClickManage}>Manage Listings</MenuItem>
                  <MenuItem className = {classes.dropDown} onClick = {handleMembership}>Membership</MenuItem>
                  <MenuItem className = {classes.dropDown} onClick = {handleSettings}>Settings</MenuItem>
                  <MenuItem className = {classes.dropDown} onClick = { ()=>{_handleLogoutClick()}}>Log Out</MenuItem>
                </div>
              </div>:
              <Button color="inherit"  className={classes.button} onClick = {() =>{_handleSignInClick()}}>Log In</Button>
            } 
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              {isLoggedIn?
                <img className={styles.smallPfp} src={getAvatar(discordId,avatar,discriminator)}  alt="Avatar" />:
                <MoreIcon/>
              }
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );

  
}
