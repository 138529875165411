import React, { useState } from 'react';
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from 'react-router-dom';

import HeaderBackground from "./HeaderBackground";

import tradecordImg from "../../assets/images/tradecord_header.png";

import styles from "./header.module.css";
import {Box, Container} from "@material-ui/core";



const Header = () => {

    const history = useHistory();
    const [search,setSearch] = useState("");

    const keyPress = (e) => {
        if(e.key === 'Enter'){
            toBrowse();
        }
     }

     const toBrowse = () =>{
         if(search.length > 0){
            history.push({
                pathname: '/browse',
                state: { search: search }
            })
        }
     }

    return(
        <div>
           <HeaderBackground />
            <Container style={{display: 'flex'}} className={styles.headerContainer}>
                <Box className={styles.headerMainContent} >
                    <img src={tradecordImg} className={styles.headerImg} alt="Tradecord logo"/>
                </Box>
                <div style={{position: 'relative'}}>
                    <input onChange={e=>setSearch(e.target.value)} className={styles.headerSearchField} type="text" placeholder="Search Bots" onKeyPress={keyPress}/>
                    <button className={styles.headerSearchBtn} onClick={toBrowse}><SearchIcon /></button>
                </div>
            </Container>
            <div className = {styles.motto}>
                <div className = {styles.mottoTop}>Now Open</div><div className = {styles.mottoMid}>for Buying, Selling, & Renting!</div>
                <div className={styles.mottoBottom}>Want to <span style={{color:"orange",textDecoration:"underline"}}>sell</span> your bot?</div>
            </div>
        </div>
    )
}


export default Header;
