import React from "react";
import styles from "./header.module.css";

const HeaderBackground = ({children}) => (
    <>
    <img className = {styles.headerBanner}src = "/images/banner2.png" alt="Banner"/>
        {children}
    </>
)

export default HeaderBackground;
