import React, {useEffect, useState} from 'react';
import styles from './button.module.css';

const TradecordButton = ({variant, children, ...props}) => {
    const [variantClass, setVariantClass] = useState(styles.btn);
    useEffect(() => {
        getVariantClass(variant);
    }, [variant])
    const getVariantClass = (variant) => {
        switch(variant){
            case 'outlined':
                setVariantClass(styles.btnOutlined)
                break;
            default:
                break;
        }
    }

    return (
        <button className={variantClass} {...props}>{children}</button>
    )
}

export default TradecordButton;
