import React, {  useState } from "react";
import axios from 'axios';
import TradecordButton from "../../components/TradecordButton";
import {paymentImage} from '../../components/Shared/Utility';

import styles from './settings.module.css';
import {Box} from "@material-ui/core";

import acceptedPayments from '../../data/payments';


/**
 * Payment Methods box on settings page
 */

export default function PaymentMethods(props) {
    
  const [payments, addPayment] = useState(props.paymentMethods);


  const savePaymentMethods = () =>{
    props.load();
    props.savePaymentMethods(payments);
    const body = {
        paymentMethods : payments
    }
    axios.post(process.env.REACT_APP_API_ENDPOINT+'settings/updatePayments',body,{withCredentials: true})
    .then(res=>{
      props.stopLoad();
    })
    .catch(err=>{
      window.alert(err.response.data);
      props.stopLoad();
    })
  }

  const addPaymentMethod = (method) => {
    if(payments.includes(method)){
      var array = [...payments]; // make a separate copy of the array
      var index = array.indexOf(method)
      if (index !== -1) {
        array.splice(index, 1);
        addPayment(array);
      }
    }
    else
    addPayment(payments => [...payments, method]);
  }

  return (
      <Box className={styles.settingsBox} mb={3}>
          <Box display="flex" justifyContent="space-between" flexDirection="column">
              <span className={styles.titleText}>Payment Methods</span>
          </Box>
        <Box className={styles.paymentWrapper}>
            {
                acceptedPayments.map(pm => {
                    return <div
                        key={pm}
                        className={`${styles.acceptedPaymentWrapper} ${payments.includes(pm) ? styles.paymentSelected : ''}`}
                        onClick={() => addPaymentMethod(pm)}
                    >
                      {paymentImage(styles.paymentImage,pm)}
                    </div>
                })
            }
        </Box>
        <TradecordButton style={{boxShadow: "none",width:'117px'}} onClick = {savePaymentMethods}>Save</TradecordButton>
      </Box>

  );
}
