import React from "react";

import styles from './categoryBar.module.css';


/**
 * The orange navbar on the browse page
 * Nav bar is different for mobile vs desktop
 * 
 */

export default function CategoryNavBar(props) {
    const category = props.displayStatus.category;
    return(
        <div>
        {props.isDesktop ?
        
        <div className = {styles.topBar}>
        <button className={category=== "WTS Bots" ? styles.underlined:null} onClick = {() =>{props.setCategory("WTS Bots")}}>WTS Bots</button>
        <button className={category=== "WTB Bots" ? styles.underlined:null} onClick = {() =>{props.setCategory("WTB Bots")}}>WTB Bots</button>
        <button className={category=== "WTRO Bots" ? styles.underlined:null} onClick = {() =>{props.setCategory("WTRO Bots")}}>WTRO Bots</button>
        <button className={category=== "WTR Bots" ? styles.underlined:null} onClick = {() =>{props.setCategory("WTR Bots")}}>WTR Bots</button>
        </div> :
        <div className = {styles.topBarMobile}>
        <button className={category=== "WTS Bots" ? styles.underlined:null} onClick = {() =>{props.setCategory("WTS Bots")}}>WTS</button>
        <button className={category=== "WTB Bots" ? styles.underlined:null} onClick = {() =>{props.setCategory("WTB Bots")}}>WTB</button>
        <button className={category=== "WTRO Bots" ? styles.underlined:null} onClick = {() =>{props.setCategory("WTRO Bots")}}>WTRO</button>
        <button className={category=== "WTR Bots" ? styles.underlined:null} onClick = {() =>{props.setCategory("WTR Bots")}}>WTR</button>
        </div>
        }
        </div>
    )

}
