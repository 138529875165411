import React from "react";

/**
 * Used to contain all static information including matching bots to their image
 * @param {*} param 
 */

export function GetImage(bot){
    switch(bot){
        case 'Balko':
            return "/images/balkobot.jpg";
        case 'Wrath':
            return "/images/Wrath.jpg";
        case 'Project Destroyer':
            return "/images/pd.jpg";
        case 'Scottbot':
            return "/images/scottbot.jpg"; 
        case 'Dashe':
            return "/images/Dashe.png";
        case 'Adept':
            return "/images/adept.jpg"; 
        case 'Backdoor':
            return "/images/backdoor.jpg"; 
        case 'BNB':
            return "/images/bnb.png"; 
        case 'Ghost':
            return "/images/Ghost.png"; 
        case 'Cyber':
            return "/images/cyber.jpg";
         case 'Dragon':
            return "/images/dragon.png"; 
        case 'eStock':
            return "/images/estock.jpg"; 
        case 'Eve':
            return "/images/eve.png"; 
        case 'F3ather':
            return "/images/f3.jpg"; 
        case 'Fleek':
            return "/images/fleek.png"; 
        case 'Ganesh':
            return "/images/ganesh.jpg"; 
        case 'Galaxsio':
            return "/images/galaxsio.jpg";     
        case 'Hawkmesh':
            return "/images/hawkmesh.jpg"; 
        case 'Hayha':
            return "/images/hayha.jpg"; 
        case 'Kodai':
            return "/images/kodai.png";
        case 'ksr':
            return "/images/ksr.jpg";  
        case 'Launcher':
            return "/images/launcher.jpg"; 
        case 'Mango':
            return "/images/mango.png";
        case 'Mek':
            return "/images/mek.jpg";      
        case 'Polaris':
            return "/images/polaris.jpg"; 
        case 'Prism':
            return "/images/prism.jpg";  
        case 'Pulsar':
            return "/images/pulsar.jpg"; 
        case 'Rush':
            return "/images/rush.jpg";
        case 'S-Chrysant':
            return "/images/schrysant.jpg";  
        case 'SoleAIO':
            return "/images/soleaio.jpg"; 
        case 'TKS':
            return "/images/tks.jpg";
        case 'Tohru':
            return "/images/tohru.jpg";   
        case 'Torpedo':
            return "/images/torpedo.jpg" 
        case 'Velox':
            return "/images/Velox.jpg"; 
        case 'Zeny':
            return "/images/zeny.jpg";             
        default:
            return "/images/empty.jpg" ;
    }
}

export function RenderImage(param,twitterURL)
{
    switch(param){
        case 'Balko':
            return "/images/balkobot.jpg";
        case 'Wrath':
            return "/images/Wrath.jpg";
        case 'Project Destroyer':
            return "/images/pd.jpg";
        case 'Scottbot':
            return "/images/scottbot.jpg";
        case 'Dashe':
            return "/images/Dashe.png" ;
        case 'Adept':
            return "/images/adept.jpg";
        case 'Backdoor':
            return "/images/backdoor.jpg";
        case 'BNB':
            return "/images/bnb.png";
        case 'Ghost':
            return "/images/Ghost.png";
        case 'Cyber':
            return "/images/cyber.jpg";
         case 'Dragon':
            return "/images/dragon.png";
        case 'eStock':
            return "/images/estock.jpg";
        case 'Eve':
            return "/images/eve.png";
        case 'F3ather':
            return "/images/f3.jpg";
        case 'Fleek':
            return "/images/fleek.png";
        case 'Ganesh':
            return "/images/ganesh.jpg";
        case 'Galaxsio':
            return "/images/galaxsio.jpg";
        case 'Hawkmesh':
            return "/images/hawkmesh.jpg";
        case 'Hayha':
            return "/images/hayha.jpg";
        case 'Kodai':
            return "/images/kodai.png";
        case 'ksr':
            return "/images/ksr.jpg";  
        case 'Launcher':
            return "/images/launcher.jpg";
        case 'Mango':
            return "/images/mango.png";
        case 'Mek':
            return "/images/mek.jpg";
        case 'Polaris':
            return "/images/polaris.jpg";
        case 'Prism':
            return "/images/prism.jpg";
        case 'Pulsar':
            return"/images/pulsar.jpg";
        case 'Rush':
            return"/images/rush.jpg";
        case 'S-Chrysant':
            return "/images/schrysant.jpg";
        case 'SoleAIO':
            return "/images/soleaio.jpg";
        case 'TKS':
            return "/images/tks.jpg";
        case 'Tohru':
            return "/images/tohru.jpg";   
        case 'Torpedo':
            return "/images/torpedo.jpg"
        case 'Velox':
            return "/images/Velox.jpg";
        case 'Zeny':
            return "/images/zeny.jpg";
        case 'Other':
            if(twitterURL)
                return twitterURL;
            else
                return "/images/empty.jpg";
        default:
            return "/images/empty.jpg";
    }
}

export function paymentImageSource(payment){
    switch(payment){
        case 'PayPal': 
            return "/images/paypal.png";
        case 'Venmo': 
            return "/images/venmo.png";
        case 'CashApp': 
            return "/images/cashapp.png";
        case 'Crypto': 
            return "/images/bitcoin.png";
        case 'BankTransfer': 
            return "/images/banktransfer.png";
        case 'Zelle': 
            return "/images/zelle.jpg";
        case 'Transferwise': 
            return "/images/transferwise.jpg";
        case 'Revolut': 
            return "/images/revolut.png";
        default:
            return  "/images/empty.jpg";
          
    }
}

export function paymentImage(style,payment){
    switch(payment){
        case 'PayPal': 
            return <img className = {style} src = "/images/paypal.png" alt=""/>;
        case 'Venmo': 
            return <img className = {style} src = "/images/venmo.png" alt=""/>;
        case 'CashApp': 
            return <img className = {style} src = "/images/cashapp.png" alt=""/>;
        case 'Crypto': 
            return <img className = {style} src = "/images/bitcoin.png" alt=""/>;
        case 'BankTransfer': 
            return <img className = {style} src = "/images/banktransfer.png" alt=""/>;
        case 'Zelle': 
            return <img style ={{borderRadius:"50%"}} className = {style} src = "/images/zelle.jpg" alt=""/>;
        case 'Transferwise': 
            return <img style ={{borderRadius:"50%"}} className = {style} src = "/images/transferwise.jpg" alt=""/>;
        case 'Revolut': 
            return <img className = {style} src = "/images/revolut.png" alt=""/>;
        default:
            return <img  className = {style} src = "/images/empty.jpg" alt=""/>;
          
    }
}

export function AllBots(){
    const bots = ["Balko","Wrath","Dashe","Dragon","Project Destroyer","Scottbot","Galaxsio",
    "Adept","Backdoor","BNB","Ghost","Cyber","eStock","Eve","F3ather","Fleek","Ganesh","Hawkmesh","Hayha",
    "Kodai","ksr","Launcher","Mango","Mek","Polaris","Prism","Pulsar","Rush","S-Chrysant","SoleAIO","TKS","Tohru","Torpedo","Velox","Zeny"];
    bots.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    bots.push("Other");
    return bots;
}

export function FilterList(){
    const bots = ["Balko","Wrath","Dashe","Dragon","Project Destroyer","Scottbot","Galaxsio",
"Adept","Backdoor","BNB","Ghost","Cyber","eStock","Eve","F3ather","Fleek","Ganesh","Hawkmesh","Hayha",
"Kodai","ksr","Launcher","Mango","Mek","Polaris","Prism","Pulsar","Rush","S-Chrysant","SoleAIO","TKS","Tohru","Torpedo","Velox","Zeny"];
    bots.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    bots.push("Other");
    bots.unshift("All");
    return bots;
}

export function AllCategories(){
    const categories = ["WTS Bots","WTB Bots","WTR Bots","WTRO Bots"];
    return categories;
}


export function ManageListingsCategories(){
    const categories = ["Active","Archived"];
    return categories;
}

export function ShortCategory(category){
    switch(category){
        case 'WTRO Bots':
            return 'WTRO';
        case  'WTS Bots':
            return 'WTS';
        case 'WTR Bots':
            return 'WTR';
        case  'WTB Bots':
            return 'WTB';
        default:
            return 'Not Found';
    }
}




