
var utility = require('../components/Shared/Utility');


exports.Supreme = [
    {
        img:utility.GetImage("Adept"),
        title:"Adept"
    },
    {
        img:utility.GetImage("Backdoor"),
        title:"Backdoor"
    },
    {
        img:utility.GetImage("Balko"),
        title:"Balko"
    },
    {
        img:utility.GetImage("Cyber"),
        title:"Cyber"
    },
    {
        img:utility.GetImage("Dashe"),
        title:"Dashe"
    },
    {
        img:utility.GetImage("Eve"),
        title:"Eve"
    },
    {
        img:utility.GetImage("F3ather"),
        title:"F3ather"
    },
    {
        img:utility.GetImage("Galaxsio"),
        title:"Galaxsio"
    },
    {
        img:utility.GetImage("Ghost"),
        title:"Ghost"
    },
    {
        img:utility.GetImage("Hayha"),
        title:"Hayha"
    },
    {
        img:utility.GetImage("Kodai"),
        title:"Kodai"
    },
    {
        img:utility.GetImage("Launcher"),
        title:"Launcher"
    },
    {
        img:utility.GetImage("Mango"),
        title:"Mango"
    },
    {
        img:utility.GetImage("Mek"),
        title:"Mek"
    },
    {
        img:utility.GetImage("Polaris"),
        title:"Polaris"
    },
    {
        img:utility.GetImage("Prism"),
        title:"Prism"
    },
    {
        img:utility.GetImage("Project Destroyer"),
        title:"Project Destroyer"
    },
    {
        img:utility.GetImage("Pulsar"),
        title:"Pulsar"
    },
    {
        img:utility.GetImage("Rush"),
        title:"Rush"
    },
    {
        img:utility.GetImage("S-Chrysant"),
        title:"S-Chrysant"
    },
    {
        img:utility.GetImage("SoleAIO"),
        title:"SoleAIO"
    },
    {
        img:utility.GetImage("TKS"),
        title:"TKS"
    },
    {
        img:utility.GetImage("Tohru"),
        title:"Tohru"
    },
    {
        img:utility.GetImage("Velox"),
        title:"Velox"
    },
    {
        img:utility.GetImage("Wrath"),
        title:"Wrath"
    },
    
]

exports.Shopfiy = [
    {
        img:utility.GetImage("Balko"),
        title:"Balko"
    },
    {
        img:utility.GetImage("Cyber"),
        title:"Cyber"
    },
    {
        img:utility.GetImage("Dashe"),
        title:"Dashe"
    },
    {
        img:utility.GetImage("Eve"),
        title:"Eve"
    },
    {
        img:utility.GetImage("Hayha"),
        title:"Hayha"
    },
    {
        img:utility.GetImage("Kodai"),
        title:"Kodai"
    },
    {
        img:utility.GetImage("Polaris"),
        title:"Polaris"
    },
    {
        img:utility.GetImage("Pulsar"),
        title:"Pulsar"
    },
    {
        img:utility.GetImage("Prism"),
        title:"Prism"
    },
    {
        img:utility.GetImage("Project Destroyer"),
        title:"Project Destroyer"
    },
    {
        img:utility.GetImage("SoleAIO"),
        title:"SoleAIO"
    },
    {
        img:utility.GetImage("TKS"),
        title:"TKS"
    },
    {
        img:utility.GetImage("Tohru"),
        title:"Tohru"
    },
    {
        img:utility.GetImage("Wrath"),
        title:"Wrath"
    },
]

exports.Footsites = [
    {
        img:utility.GetImage("Balko"),
        title:"Balko"
    },
    {
        img:utility.GetImage("Cyber"),
        title:"Cyber"
    },
    {
        img:utility.GetImage("Dragon"),
        title:"Dragon"
    },
    {
        img:utility.GetImage("Eve"),
        title:"Eve"
    },
    {
        img:utility.GetImage("Galaxsio"),
        title:"Galaxsio"
    },
    {
        img:utility.GetImage("Ganesh"),
        title:"Ganesh"
    },
    {
        img:utility.GetImage("Ghost"),
        title:"Ghost"
    },
    {
        img:utility.GetImage("Hayha"),
        title:"Hayha"
    },
    {
        img:utility.GetImage("Kodai"),
        title:"Kodai"
    },
    {
        img:utility.GetImage("ksr"),
        title:"ksr"
    },
    {
        img:utility.GetImage("Prism"),
        title:"Prism"
    },
    {
        img:utility.GetImage("Project Destroyer"),
        title:"Project Destroyer"
    },
    {
        img:utility.GetImage("Rush"),
        title:"Rush"
    },
    {
        img:utility.GetImage("SoleAIO"),
        title:"SoleAIO"
    },
    {
        img:utility.GetImage("TKS"),
        title:"TKS"
    },
    {
        img:utility.GetImage("Tohru"),
        title:"Tohru"
    },
    {
        img:utility.GetImage("Torpedo"),
        title:"Torpedo"
    },
    {
        img:utility.GetImage("Wrath"),
        title:"Wrath"
    },
]

exports.YeezySupply = [
    {
        img:utility.GetImage("Balko"),
        title:"Balko"
    },
    {
        img:utility.GetImage("Dashe"),
        title:"Dashe"
    },
    {
        img:utility.GetImage("Ghost"),
        title:"Ghost"
    },
    {
        img:utility.GetImage("Hayha"),
        title:"Hayha"
    },
    {
        img:utility.GetImage("Kodai"),
        title:"Kodai"
    },
    {
        img:utility.GetImage("Launcher"),
        title:"Launcher"
    },
    {
        img:utility.GetImage("Polaris"),
        title:"Polaris"
    },
    {
        img:utility.GetImage("Project Destroyer"),
        title:"Project Destroyer"
    },
    {
        img:utility.GetImage("Pulsar"),
        title:"Pulsar"
    },
    {
        img:utility.GetImage("Rush"),
        title:"Rush"
    },
    {
        img:utility.GetImage("SoleAIO"),
        title:"SoleAIO"
    },
    {
        img:utility.GetImage("Wrath"),
        title:"Wrath"
    },
]

exports.Nike = [
    {
        img:utility.GetImage("BNB"),
        title:"BNB"
    },
    {
        img:utility.GetImage("Dragon"),
        title:"Dragon"
    },
]

exports.Collectibles = [
    {
        img:utility.GetImage("Dragon"),
        title:"Dragon"
    },
    {
        img:utility.GetImage("eStock"),
        title:"eStock"
    },
    {
        img:utility.GetImage("Eve"),
        title:"Eve"
    },
    {
        img:utility.GetImage("Ghost"),
        title:"Ghost"
    },
    {
        img:utility.GetImage("Hayha"),
        title:"Hayha"
    },
    {
        img:utility.GetImage("ksr"),
        title:"ksr"
    },
    {
        img:utility.GetImage("Pulsar"),
        title:"Pulsar"
    },
    {
        img:utility.GetImage("Rush"),
        title:"Rush"
    },
    {
        img:utility.GetImage("Scottbot"),
        title:"Scottbot"
    },
    {
        img:utility.GetImage("TKS"),
        title:"TKS"
    },
]
