import React, {useState,createContext} from 'react';

export const UserContext = createContext();

export const UserProvider = (props) =>{
    const [user,setUser] = useState({
        stripeCustomerId:'',
        username:'',
        discordId:'',
        role:0,
        discriminator:'',
        avatar:'',
        isLoggedIn:false,
        twitterAccounts:[],
        rating:0,
        ratingCount:0,
        credit:0,
        paymentMethods:[],
        selectedTwitter:'',
        bio:''
    })
    return (
        <UserContext.Provider value ={[user,setUser]}>
            {props.children}
        </UserContext.Provider>
    );
}