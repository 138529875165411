import {FormControl, Input, MenuItem, Select} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";



const useStyles = makeStyles({

    root: {
        background: "transparent linear-gradient(90deg, #FF8900 0%, #FFB300 100%) 0% 0% no-repeat padding-box",
        borderRadius: '15px',
        height: 35.5,
        paddingLeft: '21px',
        fontFamily: 'inherit',
        fontSize: '20px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        '&::before': {
            background: "transparent linear-gradient(90deg, #FF8900 0%, #FFB300 100%) 0% 0% no-repeat padding-box",
        },
        '&::after': {
            background: "transparent linear-gradient(90deg, #FF8900 0%, #FFB300 100%) 0% 0% no-repeat padding-box",
        },
        "&:focus": {
            borderRadius:'15px'
        },
    },
    disabled: {background:"#5E606A", color:"#99999A"},
    menuStyle:{
        border: "1px solid black",
        
        backgroundColor: 'white',
        fontFamily: 'inherit',
        '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255,133,0,1)',
            borderRadius:'15px',
            outline: '1px solid slategrey'
          }
      },
    label: {
        color: "white",
        fontFamily: 'inherit',
        fontSize: '20px',
        paddingLeft: '12px',
        zIndex: 1,
    },
    icon: {
        color: 'white',
        marginRight: '10px'
    }
});

const TradecordSelect = ({id, mainLabel, value, onChange, children, fullWidth,css,disableScrollLock,disabled}) => {
    const classes = useStyles();

    return(


        <FormControl
            fullWidth={fullWidth}
    >
            <Select
                classes={{root: classes.root, icon: classes.icon,disabled: classes.disabled}}
                className = {css}
                value={value}
                onChange={onChange}
                    disableUnderline={true}
                input={<Input />}
                displayEmpty
                disabled={disabled}
                dropdownmenuprops={classes.menuStyle}
                MenuProps={{
                    disableScrollLock: disableScrollLock,
                    classes: { paper: classes.menuStyle }
                  }}
            >
                {mainLabel ? <MenuItem value="">{mainLabel}</MenuItem> : null}
                {children}
            </Select>
        {/*<InputLabel*/}
        {/*    htmlFor={id}*/}
        {/*    shrink={false}*/}
        {/*    className={classes.label}*/}
        {/*>{mainLabel}</InputLabel>*/}
        {/*<Select*/}
        {/*    id={id}*/}
        {/*    hiddenLabel="true"*/}
        {/*    className={classes.root}*/}
        {/*    inputProps={{*/}
        {/*        classes: {*/}
        {/*            icon: classes.icon,*/}
        {/*        },*/}
        {/*    }}*/}
        {/*    IconComponent={() => (*/}
        {/*        <ExpandMoreIcon className={classes.icon}/>*/}
        {/*    )}*/}
        {/*    disableUnderline={true}*/}
        {/*    onChange={onChange}*/}
        {/*>*/}
        {/*    {children}*/}
        {/*</Select>*/}
    </FormControl>
    )
}


export default TradecordSelect;
