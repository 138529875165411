import React from "react";
import PropTypes from 'prop-types';

import styles from './featureblock.module.css';
import {Box, Container} from "@material-ui/core";

import TradecordButton from "../TradecordButton";

import { useHistory } from 'react-router-dom';

const FeatureBlock = ({img, title, description, orientation, children,buttonText}) => {

    const history = useHistory();

    const toBrowse = () =>{
        if(title === "Manage")
        history.push("/manage");
        else
        history.push("/browse");
     }

    return(
        <Container className={styles.featureContainer} >
            <Box display="flex" className={orientation === 'L' ? styles.featureContentContainerLeft : styles.featureContentContainerRight}>
                <Box className={`${styles.featureContent} ${orientation === 'L' ? styles.featureContentLeft: styles.featureContentRight}`}>
                    <span className={styles.featureTitle}>{title}</span>
                    <Box>
                        <p className={styles.featureDescription}>{description}</p>
                        <TradecordButton style = {{fontSize:"20px"}} onClick = {toBrowse}>{buttonText}</TradecordButton>
                    </Box>
                </Box>
                <img className={styles.featureImage} src={img} alt={`${title}`}/>
            </Box>
        </Container>
    )
}
FeatureBlock.propTypes = {
    img: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    orientation: PropTypes.string,
    children: PropTypes.any
};
FeatureBlock.defaultProps = {
    orientation: 'R'
}

export default FeatureBlock;

