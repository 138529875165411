export default [
    'PayPal',
    'Venmo',
    'CashApp',
    'Crypto',
    'BankTransfer',
    'Zelle',
    'Revolut',
    'Transferwise'
]
