import React from 'react';
import HomePage from '../../pages/Homepage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Browse from '../../pages/Browse';
import Terms from '../../pages/TOS';
import Manage from '../../pages/Manage';
import { ProtectedDashboard } from '../Shared/protected.dashboard'
import Membership from '../../pages/Membership';
import Settings from '../../pages/Settings';

export const AppRouter = () => {
  return (
    <Router>
      <React.Fragment>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route path='/browse' component={Browse} />
          <ProtectedDashboard path='/manage' component={Manage} />
          <Route path='/terms' component={Terms} />
          <ProtectedDashboard path='/membership' component={Membership} />
          <ProtectedDashboard path='/settings' component={Settings} />
          <Route path='*' component={() => '404 NOT FOUND'} />
        </Switch>
      </React.Fragment>
    </Router>
  );
};
