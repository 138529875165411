import React from "react";
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from "./card.module.css";


export default function Card(props){

    const history = useHistory();

    const handleClickImg = () => {
        history.push({
            pathname: '/browse',
            state: { subcategory: props.title }
          })
    }
    
    return(
    <div className={styles.card}>
        <div>
            <img className={styles.cardImg} src={props.img} alt={`${props.title}`} onClick = {handleClickImg}/>
        </div>
    </div>
)
}


Card.propTypes = {
    img: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string
}

