import React, {useState} from "react";
import styles from './home.module.css';
import axios from 'axios';
import {Box} from "@material-ui/core";

export default function NewsLetter(){

    const [email,setEmail] = useState("");
    const [loading,setLoading] = useState(false);
    const [signedUp,setSignedUp] = useState(false);
    const signUp = "Sign Up";
    const load = "...";
    const success = "Success";

    const onEmailSignUp = async(event) => {
        event.preventDefault();
        setSignedUp(false);
        setLoading(true);
        console.log(email);
        axios.post(process.env.REACT_APP_API_ENDPOINT+'email/add',{email:email},{withCredentials: true})
        .then(res => {
           setSignedUp(true);
           setLoading(false);
        })
        .catch(err=>{
            window.alert(err.response.data);
            setSignedUp(false);
            setLoading(false);
        })   
    }

    return(
        <Box className={styles.newsLetter}>
            <div className={styles.newsLetterText}>
                <span className={styles.newsLetterPrimary}>Looking to keep in touch?</span>
                <p className={styles.newsLetterSecondary}>Sign up for our newsletter to get exclusive discounts and more!</p>
            </div>
            <form className={styles.contactForm}>
                <Box className={styles.contactFormContainer}>
                    <input className={styles.contactInput} placeholder="Your Email Address" onChange={e=>setEmail(e.target.value)}/>
                    <button className={styles.contactBtn} onClick={onEmailSignUp}>{signedUp?success:loading?load:signUp}</button>
                </Box>
            </form>
        </Box>
    )
}
