import React, {  useState } from "react";
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button} from '@material-ui/core';

import styles from './ProductInfo/product-info.module.css';

/**
 * Listing description tab of the popup
 */

function getTime(time){
    var date = new Date(time);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return month + "-" + day + "-" + year;
}

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '10vw',
      },
        background: 'transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;',
        borderRadius: 15,
        border: 0,
        color: 'white',
        height: 53,
        padding: '0 30px',
        textTransform: 'capitalize',
        fontFamily: 'inherit',
        fontSize: '20px'
      }
  }));

export default function ListingDescription(props) {

    const [dm,setDM] = useState("");

    const classes = useStyles();

    //Send message to user
    const onSubmit = async() =>{

        const body = {
            recieverId: props.productInfo.userId,
            bot:props.productInfo.subcategory,
            category:props.productInfo.category,
            message:dm
        }

        axios.post(process.env.REACT_APP_API_ENDPOINT + 'discord/message' ,body,{withCredentials: true})
        .then(res => {
            props.sendDM();    
        })
        .catch(err=>{
            window.alert(err.response.data);
        })
    }

    return(
        <>
            <div className = {styles.popupBasicInfo}>
                <Box className={styles.productInfoWrapper} display='flex'>
                    <Box className={styles.productInfo}>
                        <div className={styles.productDetails}>
                            <span className={styles.productHeading}>Bot</span>
                            {props.productInfo.subcategory}
                        </div>
                        <div className={styles.productDetails}>
                            <span className={styles.productHeading}>Price</span>
                            {props.productInfo.price}
                        </div>
                        {/*<div className={styles.horizontalRule}></div>*/}
                    </Box>
                    <Box className = {styles.listingDescription}>
                        {props.productInfo.description}
                    </Box>
                    {/*<h3>Listed by: {props.productInfo.username} </h3>*/}
                </Box>
                <>
                    <Box className={styles.dmContainer} display="flex">
                            <Box className = {styles.dmBar}>
                                <textarea
                                    className={styles.dmTextArea}
                                    placeholder={props.loggedIn? "I'm interested!":"Log in to send this user a message!"}
                                    onChange={e=>setDM(e.target.value)}
                                    disabled = {!props.loggedIn}
                                />
                            </Box>
                            <div className = {styles.submitDM}>
                                <Button
                                    classes={{
                                    root: classes.root,
                                    label: classes.label
                                    }}
                                    onClick = {async()=>{onSubmit()}}
                                    variant="contained" color="primary"
                                    fullWidth
                                    disabled = {!props.loggedIn}
                                >
                                    Send Message
                                </Button>
                            </div>
                        </Box>
                </>
                <h5 className = {styles.date}>Listing created on: {getTime(props.productInfo.dateCreated)}</h5>
            </div>
        </>
    )

}
