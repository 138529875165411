import React, { useState } from "react";

import {Supreme,Shopfiy,Footsites,YeezySupply,Nike,Collectibles} from "../../data/cards";
import Card from "../../components/Card";

import styles from "./home.module.css";

function getCategory(category){
    switch(category){
        case 'Footsites': return Footsites;
        case 'Supreme': return Supreme;
        case 'Shopify': return Shopfiy;
        case 'Yeezy Supply': return YeezySupply;
        case 'Nike': return Nike;
        case 'Collectibles': return Collectibles;
        default: return []; 
    }
}

export default function SitesSection(){

    const [category,setCategory] = useState("Footsites");

    return(
    <div className={styles.sites}>
        <ul className={styles.sitesHeading}>
            <li onClick = {() =>{setCategory("Footsites")}}><span className={category==="Footsites" ? styles.selectedCat:null}>Footsites</span></li>
            <li onClick = {() =>{setCategory("Shopify")}}><span  className={category==="Shopify" ? styles.selectedCat:null}>Shopify</span></li>
            <li onClick = {() =>{setCategory("Supreme")}}><span  className={category==="Supreme" ? styles.selectedCat:null}>Supreme</span></li>
            <li onClick = {() =>{setCategory("Yeezy Supply")}}><span  className={category==="Yeezy Supply" ? styles.selectedCat:null}>Yeezy Supply</span></li>
            <li onClick = {() =>{setCategory("Nike")}}><span  className={category==="Nike" ? styles.selectedCat:null}>Nike</span></li>
            <li onClick = {() =>{setCategory("Collectibles")}}><span  className={category==="Collectibles" ? styles.selectedCat:null}>Collectibles</span></li>
        </ul>
        <div className={styles.cardSection}>
            {getCategory(category).map(Bot => <Card img={Bot.img} key={Bot.title} title={Bot.title}/>)}
        </div>
    </div>
    )
}
