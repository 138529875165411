import React, {  useState } from "react";
import CheckIcon from '@material-ui/icons/Check';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';

import {AllBots} from '../../components/Shared/Utility';

import {Box, Grid,MenuItem} from "@material-ui/core";
import TradecordSelect from "../../components/TradecordSelect";
import TradecordTextField from "../../components/TradecordTextField";

import styles from './settings.module.css';
import TradecordButton from "../../components/TradecordButton";


/**
 * Webhook box on settings page
 */
export default function Webhooks(props) {
    const [category,setCategory] = useState('');
    const [bot,setBot] = useState('');
    const [webhook,setWebhook] = useState('');
    // eslint-disable-next-line
    const [allWebhooks, addWebhooks] = useState(props.webhooks);

    const onCategoryChange = (event) => {
        setCategory(event.target.value)
    }

    const onBotChange = (event) => {
        setBot(event.target.value)
    }

    const onWebhookChange = (event) => {
        setWebhook(event.target.value)
    }

    const deleteWebhook = (id) =>{
        props.load();
        axios.delete(process.env.REACT_APP_API_ENDPOINT+'settings/deleteWebhook/' + id,{withCredentials: true})
        .then(res=>{
          props.fetchWebhooks();
        })
        .catch(err=>{
          window.alert(err.response.data);
          props.stopLoad();
        })
    }

    const testWebhook = (id) =>{
        axios.post(process.env.REACT_APP_API_ENDPOINT+'settings/testWebhook/' + id,{},{withCredentials: true})
        .then(res=>{
          
        })
        .catch(err=>{
          window.alert(err.response.data);
        })
    }

    const addWebhook = () =>{
        props.load();
        const body = {
            bot: bot,
            category: category,
            webhook: webhook
        }
        axios.post(process.env.REACT_APP_API_ENDPOINT+'settings/addWebhook',body,{withCredentials: true})
        .then(res=>{
          props.fetchWebhooks();
        })
        .catch(err=>{
          window.alert(err.response.data);
          props.stopLoad();
        })
    }

    return (
        <Box className={styles.settingsBox} style={{minHeight:'250px'}} flex="1">
            <span className={styles.titleText}>Webhooks</span>
            <Box className = {styles.curWebhooks} style = {window.innerWidth  < 1184 ? {marginBottom:"50px"} : null}>
                {allWebhooks.length === 0 ? <span className = {styles.noWebhooks}>Get Discord notifications when new listings are created. Create a new webhook below!</span> : null}
                {allWebhooks.map((wh) => (
                    <Box key ={wh._id}className={styles.webhookWrapper} display="flex" justifyContent="space-between" alignItems="center">
                        <span>{wh.category}</span>
                        <span>{wh.bot}</span>
                        <Box style = {{display:"inline-flex",alignItems:"center"}}>
                            <TradecordButton style={ window.innerWidth  < 480  ? {marginRight: '15px',width:'50px', height: '40px',fontSize:'12px',padding: "0px"} :{marginRight: '15px',width:'70px',fontSize:'15px',padding: "0px"}}  onClick = {() => testWebhook(wh._id)}>Test</TradecordButton>
                            <DeleteIcon style={{color: "#FFB300",cursor:"pointer"}} onClick = {() => deleteWebhook(wh._id)} />
                        </Box>
                    </Box>
                ))}
            </Box>
            <Grid container display="flex" className = {window.innerWidth  > 484 ? styles.newWebhookContainer : styles.newWebhookContainerMobile}>
                <Grid item style = {window.innerWidth  < 484 ? {marginBottom:"10px"} : null} xs={window.innerWidth  > 484 ? 3 : null}>
                    <TradecordSelect
                        id='postType'
                        mainLabel="Category"
                        fullWidth
                        value ={category}
                        onChange = {onCategoryChange}
                        css = {window.innerWidth  < 484 ? styles.selectMobile : styles.select}
                    >
                        <MenuItem value="WTS Bots">WTS Bots</MenuItem>
                        <MenuItem value="WTB Bots">WTB Bots</MenuItem>
                        <MenuItem value="WTRO Bots">WTRO Bots</MenuItem>
                        <MenuItem value="WTR Bots">WTR Bots</MenuItem>
                    </TradecordSelect>
                </Grid>
                <Grid style = {window.innerWidth  < 484 ? {marginBottom:"10px"} : null} item xs={window.innerWidth  > 484 ? 3 : null}>
                <TradecordSelect
                    id='postType'
                    fullWidth
                    mainLabel="Bot"
                    value = {bot}
                    onChange = {onBotChange}
                    css = {window.innerWidth  < 484 ? styles.selectMobile : styles.select}
                >
                    {        
                        AllBots().map(function(bot){
                            return <MenuItem key = {bot} value={bot}>{bot}</MenuItem>
                        })
                    }    
                </TradecordSelect>
                </Grid>
                <Grid item style = {window.innerWidth  < 484 ? {marginBottom:"10px"} : null} xs={window.innerWidth  > 484 ? 3 : null}>
                    <TradecordTextField placeholder="Webhook" onChange = {onWebhookChange}/>
                </Grid>
                <Grid item xs={window.innerWidth  > 484 ? 2 : null}>
                    <Box display="flex" justifyContent="center">
                        <button className={styles.webhookButton} onClick ={addWebhook}><CheckIcon/></button>
                    </Box>
                </Grid>
            </Grid>
            {/*<div className = {styles.userWebhooks}>*/}
            {/*    {*/}
            {/*        props.webhooks.map(function(webhook){*/}
            {/*        return (*/}
            {/*        <div key={webhook._id} className = {styles.webhookList}>*/}
            {/*            <div className = {styles.webhookBotCat}><p>{ShortCategory(webhook.category)}</p></div>*/}
            {/*            <div className = {styles.webhookBotName}><p>{webhook.bot}</p></div>*/}
            {/*            <div className = {styles.webhookTestContainer}><button className = {styles.webhookTestButton} onClick = {() => testWebhook(webhook._id)}>Test</button></div>*/}
            {/*            <div className = {styles.webhookDeleteContainer}><button className = {styles.webhookDelete} onClick = {() => deleteWebhook(webhook._id)}><DeleteIcon/></button></div>*/}
            {/*        </div>*/}
            {/*        )})*/}
            {/*    }*/}
            {/*</div>*/}
            {/*<div className = {props.isDesktop? styles.newWebhook : styles.newWebhookMobile}>*/}
            {/*    <NativeSelect id="select" className={styles.selectCat} onChange={onCategoryChange}>*/}
            {/*    {*/}
            {/*        AllCategories().map(function(category){*/}
            {/*            return <option key = {category}> {category}</option>*/}
            {/*        })*/}
            {/*    }*/}
            {/*    </NativeSelect>*/}
            {/*    <NativeSelect id="select" className={styles.selectBot} onChange={onBotChange}>*/}
            {/*    {*/}
            {/*        AllBots().map(function(bot){*/}
            {/*            return <option key = {bot}>{bot}</option>*/}
            {/*        })*/}
            {/*    */}
            {/*    }*/}
            {/*    </NativeSelect>*/}
            {/*    <input disabled={props.role < 2} type="text" placeholder="Webhook" onChange = {onWebhookChange}className = {styles.webhookLink}></input>*/}
            {/*    <button className = {styles.webhookButton} onClick ={addWebhook}><CheckIcon/></button>*/}
            {/*</div>*/}

        </Box>
    );
}
