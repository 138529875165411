import React from "react";

import {FilterList} from '../../../components/Shared/Utility';

import styles from './filter-display.module.css';


/**
 * Lays out all the bots and the user can select a bot to filter by
 */

export default function FilterDisplay(props) {
    return(
        <div className = {styles.filterBar}>
                <ul className = {styles.filterList}>
                    {  
                        FilterList().map(function(subcategory){
                            return <li key = {subcategory} onClick = {() =>{props.setSubCategory(subcategory)}}> {subcategory}
                            </li>
                        })
                    }
                </ul>
        </div>
    )

}
