import React from "react";
import {Box, Grid} from "@material-ui/core";

import {paymentImage} from '../../components/Shared/Utility';

import styles from './ProductInfo/product-info.module.css';


/**
 * User profile tab of the popup
 */

function getAvatar(discordId,avatar,discriminator){
    if(!avatar) return "https://cdn.discordapp.com/embed/avatars/" + discriminator%5 +".png"
    return "https://cdn.discordapp.com/avatars/" +discordId +"/" + avatar + ".png"
  }



  const MobileUserProfile = (props) => {
      const openTwitter = () => {
          const newWindow = window.open(`https://twitter.com/${props.userProfile.selectedTwitter}`, '_blank', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null
      }
    return(
        <Box display="flex" flexDirection="column">
            <Box className={styles.profileDetails}>
                <img className={styles.profileImage} alt="avatar" src={getAvatar(props.userProfile.discordId,props.userProfile.avatar,props.userProfile.discriminator)} />
                <Box display="flex" flexDirection="column">
                    <span className={styles.profileTitle}>User</span>
                    <span className={styles.profileSecondary}>{`${props.userProfile.username}#${props.userProfile.discriminator}`}</span>
                    <span className={styles.profileReviewCount}>{props.userProfile.rating}</span>
                </Box>
            </Box>
            <Box>
                <p className={styles.profileUserDetails}>
                    {props.userProfile.bio?props.userProfile.bio:"User hasn't set a bio."}
                </p>
            </Box>

            <Box display="flex" alignItems="baseline" justifyContent="space-between">
                <Box className={styles.profileData} mt={5}>
                    <Box display="flex" flexDirection="column">
                        <span className={styles.profileTitle}>Twitter</span>
                        {props.userProfile.selectedTwitter?
                            <span className={styles.profileSecondaryLink} style = {{color:"#1DA1F2"}} onClick= {() => openTwitter()}>{props.userProfile.selectedTwitter}</span>
                            :
                            <span className={styles.profileSecondary}>No Linked Twitter</span>
                        }

                    </Box>
                    <Box display="flex" flexDirection="column">
                        <span className={styles.profileTitle}>Created</span>
                        <span className={styles.profileSecondary}>{props.userProfile.createdAt}</span>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={5.625} maxWidth='165px'>
                    <span className={styles.profileTitle}>Accepting</span>
                    <Grid container>
                        {props.userProfile.paymentMethods.map((item) => {
                            return <Grid container item xs={4} spacing={0} key={item}>
                                {paymentImage(styles.acceptingImage,item)}
                            </Grid>
                        })}
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
  }
  

const UserProfile = (props) => {

    const openTwitter = () => {
        const newWindow = window.open(`https://twitter.com/${props.userProfile.selectedTwitter}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return(
        <Box display="flex" flexDirection="column">
        <Box className={styles.profileDetails}>
            <img className={styles.profileImage} alt='avatar' src={getAvatar(props.userProfile.discordId,props.userProfile.avatar,props.userProfile.discriminator)} />
            <Box display="flex" flexDirection="column" ml={10.625}>
                <span className={styles.profileTitle}>User</span>
                <span className={styles.profileSecondary}>{`${props.userProfile.username}#${props.userProfile.discriminator}`}</span>
                <span className={styles.profileReviewCount}>{props.userProfile.rating}</span>
            </Box>
            <Box display="flex" flexDirection="column" ml={5.625} maxWidth='165px'>
                <span className={styles.profileTitle}>Accepting</span>
                <Grid container>
                    {props.userProfile.paymentMethods.map((item) => {
                        return <Grid key={item} container item xs={4} spacing={0}>
                            {paymentImage(styles.acceptingImage,item)}
                        </Grid>
                    })}
                </Grid>
            </Box>
        </Box>

            <Box display="flex" alignItems="baseline">
                <Box className={styles.profileData} mt={5}>
                    <Box display="flex" flexDirection="column">
                        <span className={styles.profileTitle}>Twitter</span>
                        {props.userProfile.selectedTwitter?
                            <span className={styles.profileSecondaryLink} style = {{color:"#1DA1F2"}} onClick= {() => openTwitter()}>{props.userProfile.selectedTwitter}</span>
                            :
                            <span className={styles.profileSecondary}>No Linked Twitter</span>
                        }

                    </Box>
                    <Box display="flex" flexDirection="column">
                        <span className={styles.profileTitle}>Created</span>
                        <span className={styles.profileSecondary}>{props.userProfile.createdAt}</span>
                    </Box>
                </Box>
                <Box  style={{width:"100%",textAlign:"start",marginLeft:'20px'}}>
                    <p className={styles.profileUserDetails}>
                        {props.userProfile.bio?props.userProfile.bio:"User hasn't set a bio."}
                    </p>
                </Box>
            </Box>

            </Box>
    )

}


export {
    UserProfile,
    MobileUserProfile
}
