import React, {  useState, useEffect } from "react";
import axios from 'axios';
import {Box} from "@material-ui/core";

import CreateListing from "../Browse/CreateListing/CreateListing";
import Spinner from '../../components/Shared/Spinner';
import Listing from "../../components/Listing";

import styles from './manage.module.css';


/**
 * loads the users listings to manage on mobile
 */

function searchUrl(subcategory)
{
    if(subcategory === "Archived")
    {
        return process.env.REACT_APP_API_ENDPOINT + 'listings/archive';
    }
    else
        return process.env.REACT_APP_API_ENDPOINT + 'listings';
}

export default function ListingManager(props) {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true);
    const [popUp,togglePopUp] = useState(false);
    const [selectedListing,setSelectedListing] = useState(null);
    var listing = props.manageListings;

    useEffect(()=>{
        if(props.isMounted){
            setLoading(true);
            setPosts([]);
            async function getData(){
                try{
                    axios.get(searchUrl(props.displayStatus.subcategory),{withCredentials: true})
                    .then(res => {
                        setLoading(false);
                        setPosts(res.data)

                    })
                    .catch(err=>{
                        window.alert(err.response.data);
                    })
                }
                catch(err)
                {
                    if(err.response.status === 401 || err.response.status === 403)
                        window.alert('Access Denied')
                    if(err.response.status === 503)
                        window.alert('The server is currently experiencing too much traffic. Try again later')
                }
            }
            getData();
        }

    },[props.displayStatus,props.isMounted])

    const archiveListing = (id) =>{
        axios.post(process.env.REACT_APP_API_ENDPOINT + 'listings/archive/' + id,listing,{withCredentials: true})
            .then(res => {
               props.archive();               
            })
            .catch(err=>{
                window.alert(err.response.data);
            })
    }

    const deleteListing = (id) =>{
        axios.delete(process.env.REACT_APP_API_ENDPOINT + 'listings/delete/' + id,{withCredentials: true})
            .then(res => {
               props.delete();
            })
            .catch(err=>{
                window.alert(err.response.data);
            })
    }

    const relistListing = (id) =>{
        axios.post(process.env.REACT_APP_API_ENDPOINT + 'listings/relist/' + id,listing,{withCredentials: true})
            .then(res => {
                props.relist();
            })
            .catch(err=>{
                window.alert(err.response.data);
            })
    }

    const handleEdit = (listing) => {
        togglePopUp(true);
        setSelectedListing(listing);
    }

    const toggleForm = () =>{
        togglePopUp(false);
    }
    
    

    return(
        <div className = {styles.manageListingsMobileOuter}>
           <h1 className={styles.manageListingTitle} style={{marginLeft:20}}>{props.displayStatus.subcategory} Listings</h1>
           {/*<h2>{posts.length} listings</h2>*/}
           {loading ? <div className = {styles.spinner}><Spinner /></div> : posts.length === 0 && !loading ? <h1>No results</h1> : null}
            <Box className = {styles.manageListingsMobile}>
                {/*{props.displayStatus.subcategory === "Active Listings" ?                       */}
                {/*    posts.map( post => <div key ={post._id} >*/}
                {/*        {RenderImage(post.subcategory)} {post.title} {post.price}*/}
                {/*            <ActiveListingsMenu post = {post} listing = {listing} archive= {props.archive} discordId = {props.discordId}/>*/}
                {/*            </div>)*/}
                {/*: */}
                {/*    posts.map( post => <div key ={post._id} >*/}
                {/*        {RenderImage(post.subcategory)} {post.title} {post.price}*/}
                {/*            <ArchivesListingsMenu post = {post} listing = {listing} delete = {props.delete} relist = {props.relist}  discordId = {props.discordId}/>                   */}
                {/*            </div>)*/}
                {/*    } */}
                {posts.map(post => <Listing key={post._id} post = {post} handleEdit = {handleEdit} archiveListing = {archiveListing} deleteListing = {deleteListing} relistListing = {relistListing} displayStatus={props.displayStatus}/>)}

            </Box>
            {popUp ? <CreateListing
            toggleForm= {toggleForm}
            isDesktop={true}
            refresh={props.editListing}
            listing = {selectedListing}
         /> : null}
        </div>
    )

}
