import React from "react";

import {ManageListingsCategories} from '../../components/Shared/Utility';

import styles from './manage.module.css';

/**
 * The filter area for the /manage route
 */


export default function FilterDisplay(props) {
    return(
        <div className = {styles.filterBar}>
                <ul className = {styles.filterList}>
                    {
                        ManageListingsCategories().map(function(subcategory){
                            return <li key = {subcategory} onClick = {() =>{props.setSubCategory(subcategory)}}> {subcategory}</li>
                        
                        })
                    }
                </ul>
        </div>
    )

}
