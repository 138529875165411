import React, {useState} from "react";
import axios from 'axios';
import XButton from '@material-ui/icons/Close';

import {
    Box, MenuItem,
} from '@material-ui/core';

import {AllBots} from '../../../components/Shared/Utility';
import makeStyles from "@material-ui/core/styles/makeStyles";
import TradecordSelect from "../../../components/TradecordSelect";
import TradecordTextField from "../../../components/TradecordTextField";
import TradecordButton from "../../../components/TradecordButton";

import styles from './create-listing.module.css';


const useStyles = makeStyles({
    root: {
        background: 'white',
        borderRadius: 15,
        border: 0,
        color: '#9C9C9C',
        height: 48,
        fontSize: '20px',
        padding: '0 30px',
        marginBottom: '17px'
    },
    input: {
        '&::placeholder': {
            color: '#9C9C9C'
        },
    },
    label: {
        textTransform: 'capitalize',
    },
    listingContainer: {
        maxWidth: '525px'
    },
    icon: {
        color: 'white',
        marginRight: '10px'
    },
    formControl: {
        root: {}
    }
});


/**
 * Popup when create listing button is clicked.
 * User fills out form and api call is made on submit
 */

export default function CreateListing(props) {
    const classes = useStyles();

    const [title, setTitle] = useState(props.listing?props.listing.title:"");
    const [subcategory, setSubcategory] = useState(props.listing?props.listing.subcategory:"");
    const [description, setDescription] = useState(props.listing?props.listing.description:"");
    const [category, setCategory] = useState(props.listing?props.listing.category:"");
    const [type, setType] = useState(props.listing?props.listing.type? props.listing.type: "":"");
    const [price, setPrice] = useState(props.listing?props.listing.price:"");
    const [url, setURL] = useState(props.listing?props.listing.twitterHandle:"");


    // TODO: Get rid of duplicate on change functions.

    const onSubcategoryChange = (event) => {
        setSubcategory(event.target.value);
    }

    const onCategoryChange = (event) => {
        setCategory(event.target.value);
    }

    const onTypeChane = (event) => {
        setType(event.target.value);
    }

    const onSubmit = async () => {
        const listing = {
            title: title,
            description: description,
            price: price,
            category: category,
            subcategory: subcategory,
            twitterURL: url,
            type: type
        }
        if(props.listing)
            listing._id = props.listing._id;

        //Add listing to database and refresh product display
        axios.post(process.env.REACT_APP_API_ENDPOINT + 'listings/create', listing, {withCredentials: true})
            .then(res => {
                props.toggleForm();
                if(!props.listing){ //new listing
                    props.addConfirmationInfo(title,res.data.id)
                    props.toggleConfirmation();
                }
                props.refresh();
            })
            .catch(err => {
                window.alert(err.response.data);
            })
    }


    
    return (
        <Box className={styles.popup}>
            <Box className={props.isDesktop ? styles.popupInner : styles.popupInnerMobile}>
                  <span className={styles.popupTitle} style={{display: 'flex', justifyContent: 'space-between'}}>
                      {props.listing? "Edit Listing" :"Create Listing"}
                      <XButton
                          className={styles.closeButton}
                          onClick={props.toggleForm}
                          fontSize="large"
                      />
                  </span>
                <Box display="flex" className={styles.listingInfoContainer}>
                    <Box>
                    <Box className={styles.listingInputWrapper}>
                        <TradecordTextField
                            placeholder="Title of Listing *"
                            inputprops={{classes: classes, disableUnderline: true}}
                            onChange={e=>setTitle(e.target.value)}
                            value={title}
                        />
                    </Box>
                    <Box className={styles.listingInputWrapper}>
                                      <textarea
                                          className={styles.textArea}
                                          placeholder="Listing Description"
                                          onChange={e=>setDescription(e.target.value)}
                                          value = {description}
                                      />
                    </Box>
                    <Box className={styles.listingInputWrapper}>
                        <TradecordTextField
                            placeholder="Price *"
                            inputprops={{classes: classes, disableUnderline: true}}
                            onChange={e=>setPrice(e.target.value)}
                            value = {price}
                        />
                    </Box>
                </Box>
                    <Box>
                    <Box className={styles.listingSelectContainer}>
                        <Box className={styles.listingInputWrapper}>
                            <TradecordSelect
                                fullWidth
                                value = {subcategory}
                                mainLabel={props.isDesktop?"Select Bot":"Bot"}
                                onChange={onSubcategoryChange}
                                css = {styles.select}
                                disableScrollLock = {true}
                            >
                                {        
                                    AllBots().map(function(bot){
                                        return <MenuItem key={bot} value={bot}>{bot}</MenuItem>
                                    })
                                }    
                            </TradecordSelect>
                        </Box>
                        <Box display ="flex">
                            <Box width={!props.isDesktop? 1/4: null} className={styles.listingInputWrapper}>
                                <TradecordSelect
                                    fullWidth
                                    value = {category}
                                    mainLabel={"Category"}
                                    onChange={onCategoryChange}
                                    css = {styles.select}
                                    disableScrollLock = {true}
                                >
                                    <MenuItem value="WTS Bots">WTS Bots</MenuItem>
                                    <MenuItem value="WTB Bots">WTB Bots</MenuItem>
                                    <MenuItem value="WTRO Bots">WTRO Bots</MenuItem>
                                    <MenuItem value="WTR Bots">WTR Bots</MenuItem>
                                </TradecordSelect>
                            </Box>
                            <Box width={!props.isDesktop? 1/4: null} className={styles.listingInputWrapper}>
                                <TradecordSelect
                                    fullWidth
                                    value = {type}
                                    mainLabel={"Type"}
                                    onChange={onTypeChane}
                                    css = {styles.select}
                                    disableScrollLock = {true}
                                    disabled={category==="WTR Bots" || category==="WTRO Bots"}
                                >
                                    <MenuItem value="lifetime">Lifetime</MenuItem>
                                    <MenuItem value="renewal">Renewal</MenuItem>
                                    <MenuItem value="n/a">N/A</MenuItem>
                                </TradecordSelect>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={styles.listingInputWrapper}>
                        <TradecordTextField
                            placeholder="Bot's Twitter Handle"
                            onChange={e=>setURL(e.target.value)}
                            disabled={subcategory !== "Other" }
                            value = {url}
                        />
                    </Box>
                    <Box className={styles.buttonContainer} display="flex" width="100%">
                        <TradecordButton  style={{boxShadow: "none"}} variant="outlined" onClick={props.toggleForm}>Discard</TradecordButton>
                        <TradecordButton  style={{boxShadow: "none"}} variant="filled" onClick = {async()=>{onSubmit()}}>{props.listing?"Edit":"Create"}</TradecordButton>
                    </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
} 
