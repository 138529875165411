import React from "react";
import styles from "./index.module.css";

const TradecordTextField = ({onChange, ...props}) => {
    return(
        <input {...props} className={styles.input} onChange={onChange}/>
    )
}

export default TradecordTextField;
