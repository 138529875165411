import React, {useState, useEffect } from "react";
import queryString from'query-string';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";



import CheckoutForm from "./CheckoutForm";
import Spinner from '../../components/Shared/Spinner';
import CancelSubscription from "./CancelSubscription";
import Navbar from "../../components/Navbar";
import {Footer, MobileFooter} from "../../components/Footer"
import UserLogIn from '../../components/Shared/UserLogIn';
import CheckIfDesktop from '../../components/Shared/CheckIfDesktop';
import {BREAKPOINTS} from "../../utilities/constants";
import styles from './membership.module.css';


/**
 * Parent component for Membership Page
 * Users will see different cards depening on their status.
 * Lifetime members and banned users wont see a card
 */

function getExpDate(expTime){ 
  var date = new Date(expTime * 1000);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return month + "-" + day + "-" + year;
}

/**
 *  Parent component for the membership , '/membership route'
 */

const promise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);


export default function Manage(props) {

  // eslint-disable-next-line
  const [isMounted,setIsMounted] = useState(false)
  const isDesktop = CheckIfDesktop(500,setIsMounted);
  const user = UserLogIn(setIsMounted);
  const parsed = queryString.parse(props.location.search);
  const [price,setPrice] = useState(null);
  const [last4,setLast4] = useState(null);
  const [status,setStatus] = useState(null);
  const [expDate,setExpDate] = useState(null);
  const [subActive,setSubActive] = useState(false);
  const [cardBrand,setCardBrand] = useState(null);
  const [loading,setLoading] = useState(true);
  const [subId,setSubId] = useState(null);
  const [update,setUpdate] = useState(false);
  const [resubscribe,setResubscribe] = useState(false);
  const [notInDiscord,setNotInDiscord] = useState(false);
  const referralCode = parsed.ref?parsed.ref:'';
  

  useEffect(()=>{
    if(user.isLoggedIn)
    fetchUserAccount();
  },[user]);

  const fetchUserAccount = () =>{
    fetch(process.env.REACT_APP_API_ENDPOINT + 'stripe/subInfo',{
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    }).then(response =>{
      if(response.status === 200) return response.json();
      if(response.status === 500){
        window.alert("Server error, please alert staff!")
        setLoading(false);
        setUpdate(false);
        setNotInDiscord(false);
      }
      if(response.status === 404){ //not subscriber
        setSubActive(false);
        setLoading(false);
        setUpdate(false);
        setNotInDiscord(false);
      }
      if(response.status === 403){ //user is banned/not in discord
        setSubActive(false);
        setLoading(false);
        setUpdate(false);
        setNotInDiscord(true);
      }
    })
    .then(subData =>{ //user is subscriber
      setPrice(subData.price);
      setLast4(subData.last4);
      setStatus(subData.status);
      setExpDate(subData.expireDate);
      setSubActive(true);
      setCardBrand(subData.cardBrand);
      setLoading(false);
      setSubId(subData.subId);
      setUpdate(false);
      setResubscribe(false);
      setNotInDiscord(false);
    })
    .catch(error=>{
     
    })  
  }

  const changePayment = () =>{
    setSubActive(false);
    setUpdate(true);
    setResubscribe(false);
  }

  const resubscribing = () =>{
    setSubActive(false);
    setResubscribe(true);
  }

    return (
        <div>
            <div className = {styles.dashboard}>
              <Navbar
                onBrowse = {false}
                isDesktop = {isDesktop}
                user = {user}
                onHome = {false}
              />
                { notInDiscord ? 
                  <div>
                    <div className = {styles.lifeTime}>
                        <p>You must be a member of the discord to subscribe or edit your subscription. If you were banned, your membership has been canceled.</p>
                    </div>
                  </div>
                  :
                  <div>
                    {user.role === '3' ? 
                      <div className = {styles.lifeTime}>
                        <p>You are a lifetime member.</p>
                        <p>No Subscription required.</p>
                      </div>
                      : 
                      <div>
                        {loading? <div className = {!isDesktop ? styles.spinnerMobile : styles.spinner}><Spinner /></div> :
                        !subActive  ?
                          <div className = {isDesktop ? styles.membership : styles.membershipMobile}>
                            <h1 className = {styles.stripeTitle}>Premium Membership</h1>
                            <h1 className = {styles.stripePrice}>$1.95/month</h1>
                            <p><span>&#8594;</span> Exclusive groupbuy & giveaway channels</p>
                            <p><span>&#8594;</span> 10% off MM fees (always) with occasional bigger flash discounts</p>
                            <p><span>&#8594;</span> Access to referral system: refer friends and receive Middleman credit!</p>
                            <p><span>&#8594;</span> Keep this price forever</p>
                            <p><span>&#8594;</span> More coming soon!</p>
                            <Elements stripe={promise}>
                              <CheckoutForm
                                fetchAccountInfo = {fetchUserAccount}
                                update = {update}
                                subId = {subId}
                                resubscribe = {resubscribe}
                                referralCode = {referralCode}
                                user = {user}
                                isDesktop = {isDesktop}
                              />
                            </Elements>
                          </div>
                        :
                          <div className = { isDesktop ? styles.subActive : styles.subActiveMobile}>
                              <h1 className = {styles.stripeTitle} >Subscription Information</h1>
                              <p>Status: {status}</p>
                              <p>Price: ${price}</p>
                              <p>Card: {cardBrand} •••• {last4}  </p>
                              
                              { status === "active"?
                              <div className = {styles.subP}>
                              <p>Renewal Date: { getExpDate(expDate)}</p>
                              <button className={styles.changePayment} onClick={changePayment}>Change Payment Method</button>
                              <CancelSubscription
                                subId = {subId}
                                fetchAccountInfo = {fetchUserAccount}
                                isDesktop = {isDesktop}
                              />
                              </div>
                              :
                              <div className = {styles.subP}> 
                                <p>You will lose membership privileges on: <br/>{ getExpDate(expDate)} </p>
                                <button className={styles.resub} onClick={resubscribing}>Resubscribe</button>
                              </div>
                              }
                          </div>
                        }
                    </div>
                    }
                  </div>
                }
            </div>
            {window.innerWidth < BREAKPOINTS.MD ? <MobileFooter/> : <Footer />}
      </div>
    );
  }

