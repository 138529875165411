import React from "react";
import XButton from '@material-ui/icons/Close';

import {Box} from '@material-ui/core';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import styles from './confirmation.module.css';


function getTweetText(title,id){
    return `http://twitter.com/intent/tweet?text=${title}.%20Check%20out%20my%20new%20listing%20on%20Tradecord!%0Ahttps%3A%2F%2Fwww.tradecord.io%2Fbrowse%3Flisting%3D${id}`;
}

/**
 * Confirmation the user sees after a listing is created
 */

export default function Confirmation(props) {
    

    return (
        <Box className={styles.popup}>
            <Box className={props.isDesktop ? styles.popupInner : styles.popupInnerMobile}>
                <span className={styles.popupTitle} style={{display: 'flex', justifyContent: 'space-between'}}>
                    {props.isDesktop? "Listing Created!":"Success!"}
                    <XButton
                        className={styles.closeButton}
                        onClick={props.toggleForm}
                        fontSize="large"
                    />
                </span>
                <Box>
                    <div className = {styles.support}>Share your listing with the world!</div>
                    <CopyToClipboard  onCopy={props.copyToClipboard} text={`https://www.tradecord.io/browse?listing=${props.confirmationInfo.id}`}>
                        <div className={styles.shareButton} >Copy to clipboard</div>
                    </CopyToClipboard> 
                    <a href={getTweetText(props.confirmationInfo.title,props.confirmationInfo.id)} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
                        <div className={styles.shareButton} style={{background:"#1DA1F2"}}>Tweet</div> 
                    </a>
                </Box>
            </Box>
        </Box>
    );
} 
