import React, { useState } from "react";
import queryString from'query-string';
import { toast } from 'react-toastify';
import ProductDisplay from './ProductDisplay/ProductDisplay';
import FilterDisplay from './FilterDisplay/FilterDisplay';
import Navbar from "../../components/Navbar";
import CategoryBar from './CategoryBar/CategoryBar';
import {Footer, MobileFooter} from "../../components/Footer";
import UserLogIn from '../../components/Shared/UserLogIn';
import CheckIfDesktop from '../../components/Shared/CheckIfDesktop';
import styles from './browse.module.css';
import Sidebar from "./Sidebar/Sidebar";
import {BREAKPOINTS} from "../../utilities/constants";



/**
 * The parent component for the browse page
 * Contains the child components for the filters area,  the product display, and the orange navbar
 *  
 */




const Browse = (props) => {
    const [isMounted,setIsMounted] = useState(false)
    const isDesktop = CheckIfDesktop(500,setIsMounted);
    const user = UserLogIn(setIsMounted);
    const parsed = queryString.parse(props.location.search);
    const [displayStatus, setDisplayStatus] = useState({
      category: "WTS Bots",
      subcategory: props.location.state? props.location.state.subcategory:"",
      search: props.location.state? props.location.state.search:"",
      refreshListings: false,
      listingId: parsed.listing?parsed.listing:"",
      premium:false,
      twitter_connected:false,
      lifetime:false,
      renewal:false
    })


    //WTS,WTB, etc.
    const setCategory = (category) =>{
      setDisplayStatus({
        ...displayStatus,
        category:category,
        subcategory: "",
        search:"",
        listingId:""
      })
    }

    //Name of bot
    const setSubCategory =(subcategory) =>{
      if(subcategory !== "All"){
        setDisplayStatus({
          ...displayStatus,
          subcategory:subcategory,
          search:"",
          listingId:""
        })
      }
      else{
        setDisplayStatus({
          ...displayStatus,
          subcategory: "",
          search: "",
          listingId:""
        })
      }
    }


    const setFilters = (filter) =>{
      switch(filter){
        case 'premium': setDisplayStatus({
          ...displayStatus,
          premium:!displayStatus.premium
        });
        break;
        case 'twitter_connected': setDisplayStatus({
          ...displayStatus,
          twitter_connected:!displayStatus.twitter_connected
        });
        break;
        case 'lifetime': setDisplayStatus({
          ...displayStatus,
          lifetime:!displayStatus.lifetime
        });
        break;
        case 'renewal': setDisplayStatus({
          ...displayStatus,
          renewal:!displayStatus.renewal
        });
        break;
        default: 
          break;
      }
    }

    const createListing = () => {
      setDisplayStatus({
        ...displayStatus,
        refreshListings: !displayStatus.refreshListings
      })
    }

    const copyToClipboard = () =>{
      toast("Copied to clipboard!");
    }

  /*
    const refreshListings = () => {
      setDisplayStatus({
        ...displayStatus,
        refreshListings: !displayStatus.refreshListings
      })
      toast("Refreshed!");
    }*/

    const sendDM = () =>{
      toast("Message delivered!"); 
    }

    const setSearch = (query) =>{
      setDisplayStatus({
        ...displayStatus,
        search:query
      })
    }

    const deleteListing = () => {
      setDisplayStatus({
        ...displayStatus,
        refreshListings: !displayStatus.refreshListings
      })
      toast("Listing Deleted!");    
    }
   
    function renderFilterArea(){
        if(window.innerWidth > BREAKPOINTS.LG) {
            return <FilterDisplay
                displayStatus={displayStatus}
                setSubCategory={setSubCategory}
            />
        }
    }

    function renderContentArea(){ 
      return <ProductDisplay 
        isDesktop = {isDesktop} 
        displayStatus = {displayStatus} 
        loggedIn = {user.isLoggedIn}
        sendDM = {sendDM}
        deleteListing = {deleteListing}
        isMounted = {isMounted}
        copyToClipboard = {copyToClipboard}
      />
    }

    const renderSidebar = () => {
        return <Sidebar
            isDesktop = {isDesktop}
            refresh = {createListing}
            setFilters = {setFilters}
            displayStatus = {displayStatus}
            setSubCategory={setSubCategory}
            loggedIn = {user.isLoggedIn}
            copyToClipboard = {copyToClipboard}
        />
    }
   
    return (
        <>
          <div className = {styles.dashboard}>
            <div>
              <Navbar
                setSearch = {setSearch} 
                onBrowse = {true}
                isDesktop = {isDesktop}
                user = {user}
                onHome = {false}
              />
              <CategoryBar
              setCategory = {setCategory}
              displayStatus = {displayStatus}
              isDesktop = {isDesktop}
              />
                      <div className = {styles.dashWrapper}>
                        {renderFilterArea()}
                        {renderContentArea()}
                          {renderSidebar()}
                      </div>


            </div>
          </div>
            

            {window.innerWidth < BREAKPOINTS.MD ? <MobileFooter/> : <Footer />}
        </>
    );
  }

export default Browse;
