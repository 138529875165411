import React, {useState} from 'react';

import {Fab, Box, Checkbox, FormControlLabel, MenuItem} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import makeStyles from "@material-ui/core/styles/makeStyles";

import CreateListing from "../CreateListing/CreateListing";
import Confirmation from "../Confirmation/Confirmation";
import {BREAKPOINTS} from "../../../utilities/constants";

import TradecordSelect from "../../../components/TradecordSelect";
import {FilterList} from '../../../components/Shared/Utility';

import styles from './sidebar.module.css';



const filters = [{label: "Premium Members", name: "premium"}, {
    label: "Twitter Connected",
    name: "twitter_connected"
},{label: "Renewal", name: 'renewal'}, {label: "Lifetime", name: "lifetime"}]

const useStyles = makeStyles({

    root: {
        background: "transparent linear-gradient(90deg, #FF8900 0%, #FFB300 100%) 0% 0% no-repeat padding-box",
        borderRadius: '15px',
        paddingLeft: '21px',
        fontFamily: 'inherit',
        fontSize: '20px',
        maxWidth: '250px',
        color: 'white',
        '&::before': {
            background: "transparent linear-gradient(90deg, #FF8900 0%, #FFB300 100%) 0% 0% no-repeat padding-box",
        },
        '&::after': {
            background: "transparent linear-gradient(90deg, #FF8900 0%, #FFB300 100%) 0% 0% no-repeat padding-box",
        }

    },
    label: {
        color: "white",
        fontFamily: 'inherit',
        fontSize: '20px',
        paddingLeft: '12px',
        zIndex: 1,
    },
    icon: {
        color: 'white',
        marginRight: '10px'
    }
});

const Sidebar = (props) => {

    const classes = useStyles();

    const [listingOpen, setListingOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationInfo, setConfirmationInfo] = useState({
        title:"",
        id:null
      })

    const toggleForm = () => {
        setListingOpen(!listingOpen);
    }

    const toggleConfirmation = () => {
        setConfirmationOpen(!confirmationOpen);
    }




    const generateFilterList = () => {
        return filters.map((filter) =>
                <li key={filter.name}><FormControlLabel
                    classes={{label: classes.label}}
                    control={<Checkbox icon={<RadioButtonUncheckedIcon style={{color: "#696969"}}/>}
                                       checkedIcon={<RadioButtonCheckedIcon style={{color: "#FFB300"}}/>}
                                       name={filter.name}
                                       checked = {props.displayStatus[filter.name]}
                                       onChange={()=>{props.setFilters(filter.name)}}/>}
                    label={filter.label}
                />
                </li>
        )
    }

    const addConfirmationInfo = (title,id) =>{
        setConfirmationInfo({
            title:title,
            id:id
        })
    }

    /**
     * TODO: Ensure that bot names are returned here on desktop
     * @returns {JSX.Element}
     */
    const displayBots = () => {
        return  <TradecordSelect
            fullWidth = {false}
            css = {styles.buttonWidth}
            value = {props.displayStatus.subcategory}
            mainLabel="Select Bot"
            onChange={(e)=>props.setSubCategory(e.target.value)}
        >
           { FilterList().map(function(subcategory){

                            return <MenuItem key = {subcategory} value = {subcategory}>{subcategory}</MenuItem>
                        })
                    }
        </TradecordSelect>
    }

    return <div className={styles.sidebar}>
        {BREAKPOINTS.LG <= window.innerWidth && props.loggedIn? <button className={styles.browseButton} onClick={toggleForm}>Create</button> : null}
        <div className={styles.filtersWrapper}>
        <span className={BREAKPOINTS.LG > window.innerWidth ? styles.filterListHeaderMobile: styles.filterListHeader} onClick = {BREAKPOINTS.LG > window.innerWidth ? ()=>setFilterOpen(!filterOpen):null}>Filters</span>
              
            <Box className={styles.filterListContainer}>
                {BREAKPOINTS.LG < window.innerWidth || filterOpen?
                <ul className={styles.filterGroup}>
                    {generateFilterList()}
                </ul>
                :null
                }
            </Box>
            {BREAKPOINTS.LG > window.innerWidth && filterOpen ? displayBots() : null}
        </div>
        {BREAKPOINTS.LG > window.innerWidth && props.loggedIn ? <Fab color="primary" aria-label="add" className={styles.mobileButton} onClick = {toggleForm}>
                <AddIcon />
            </Fab>: null}
        {listingOpen ? <CreateListing
            toggleForm= {toggleForm}
            toggleConfirmation = {toggleConfirmation}
            addConfirmationInfo = {addConfirmationInfo}
            isDesktop={props.isDesktop}
            refresh={props.refresh}
        /> : null}
        {confirmationOpen ? <Confirmation
          toggleForm= {toggleConfirmation}
          isDesktop={props.isDesktop}
          confirmationInfo = {confirmationInfo}
          copyToClipboard = {props.copyToClipboard}
        /> : null}
    </div>
}

export default Sidebar;
