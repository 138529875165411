import React, { useState } from 'react';
import styles from './membership.module.css';

/**
 * Prompt if user wants to continue when subscription is canceled. If yes, Subscription is canceled -> else hide prompt 
 */

function CancelSubscription(props) {

  const [isOpen, setIsOpen] = useState(false)

  const togglePopUp = () => {
    setIsOpen(!isOpen)
  };

  const handleClickToggle = (evt) => {

    evt.preventDefault();
    togglePopUp();
  };

  const handleClickYes = (evt) => {

    evt.preventDefault();
    return fetch(process.env.REACT_APP_API_ENDPOINT + 'stripe/cancel-subscription', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: props.subId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((cancelSubscriptionResponse) => {
        // Display to the user that the subscription has been cancelled.
        props.fetchAccountInfo();
      });
  };


  return (
    <div>
      {isOpen ? 
        <div className = {styles.popup}>
          <div className = {props.isDesktop ? styles.cancelInner : styles.cancelInnerMobile}>
            <p>Once you cancel a subscription, it can't be undone. You will still have membership privileges until the renewal date. You will need to create a new subscription if you change your mind. Would you like to proceed?</p>
            <button style={{background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,115,115,1) 0%, rgba(255,0,0,1) 100%)",fontFamily:"inherit",color:"white"}} onClick={handleClickToggle}>No</button>
            <button style={{background: "transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box",fontFamily:"inherit"}} onClick={handleClickYes}>Yes</button>
          </div>
        </div>
      :
        null
      }
    <button className = {styles.cancel} onClick={handleClickToggle} type="submit">Cancel Subscription</button>
    </div>
    
  );
}

export default CancelSubscription;