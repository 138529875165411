import feature1Img from '../assets/images/feature-1.png';
import feature2Img from '../assets/images/feature-2.png';
import feature3Img from '../assets/images/feature-3.png';

export default [
    {
        id: 'feature-1',
        image: feature1Img,
        title: 'Instant Listings',
        description: 'Users can easily list their bots in the marketplace for all other users to view instantly.',
        orientation: 'L',
        buttonText: 'Try it Out'
    },
    {
        id: 'feature-2',
        image: feature2Img,
        title: 'Navigation',
        description: 'Locate the exchange that fits your needs with our search and filtering features.',
        orientation: 'R',
        buttonText: 'Search Bots'
    },
    {
        id: 'feature-3',
        image: feature3Img,
        title: 'Manage',
        description: 'Edit, Archive, Delete, and Relist your listings using the listing management system.',
        orientation: 'L',
        buttonText: 'Try it Out'
    }
]
