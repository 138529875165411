import React, { useState } from "react";

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import UserLogIn from '../../components/Shared/UserLogIn';
import CheckIfDesktop from '../../components/Shared/CheckIfDesktop';

import styles from './termsofservice.module.css';


/**
 * Terms of service page and info, pretty straightforward here
 */

export default function TermsofService(){

    // eslint-disable-next-line
    const [isMounted,setIsMounted] = useState(false)
    const isDesktop = CheckIfDesktop(500,setIsMounted);
    const user = UserLogIn(setIsMounted);


    return (
        <div>
            <Navbar
                onBrowse = {false}
                isDesktop = {isDesktop}
                user = {user}
                onHome = {false}
            />
            <div className = {styles.terms}>
                <h1 className = {styles.tosHeader}>Terms of Service</h1>
                <div className = {styles.termsList}>
                    <h2>For users:</h2>
                    <h3>By logging into Tradecord, you agree that:</h3>
                    <p>1. We are not responsible for the loss of any software or physical goods for any reason including scams.</p>
                    <p>2. We reserve the right to ban your discord account from Tradecord at any time for any reason.</p>
                    <p>For the full terms of service, please read the #rules channel in the Tradecord Discord Server.</p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

