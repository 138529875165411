import React, {  useState } from "react";


import styles from './settings.module.css';
import {Box, MenuItem} from "@material-ui/core";
import TradecordSelect from "../../components/TradecordSelect";
import TradecordButton from "../../components/TradecordButton";
import axios from 'axios';


/**
 * Twitter box on settings page
 */

export default function TwitterAccount(props) {

    const [twitter,setTwitter] = useState("");
    const curTwitter = props.curTwitter;

    const saveTwitter = () => {
        props.load();
        props.setCurTwitter(twitter);
        axios.post(process.env.REACT_APP_API_ENDPOINT+'settings/updateTwitter',{newTwitter:twitter},{withCredentials: true})
        .then(res=>{
          props.stopLoad();
        })
        .catch(err=>{
          props.stopLoad();
          window.alert(err.response.data);
        })
    };

    const onTwitterChange = (event) => {
        setTwitter(event.target.value)
    }

    const logout = () =>{
        props.load();
        props.setCurTwitter(twitter);
        axios.post(process.env.REACT_APP_API_ENDPOINT+'settings/updateTwitter',{newTwitter:""},{withCredentials: true})
        .then(res=>{
          props.stopLoad();
        })
        .catch(err=>{
          props.stopLoad();
          window.alert(err.response.data);
        })
    }



    return (
       <Box className={styles.settingsBox} flex="1" mb={3}>
           <Box display="flex" justifyContent="space-between">
               <span className={styles.titleText}>Twitter</span>
               <span style = {{cursor:"pointer"}}className={styles.bodyText} onClick={logout}>Logout</span>
           </Box>
           <Box>
               <span className={styles.bodyText}>Currently / <span style={{color: 'white'}}>{curTwitter? `@${curTwitter}`: 'Not Selected'}</span></span>
           </Box>
           <Box >
            <TradecordSelect
                    mainLabel="Select Twitter"
                    value={twitter} 
                    onChange={onTwitterChange}
                    css = {window.innerWidth  > 1184 ? styles.twitterSelect : styles.twitterSelectMobile}
                >
                    {
                        props.user.twitterAccounts.map(function(account){
                            return <MenuItem key={account.name} value = {account.name}> {account.name}</MenuItem>
                        })
                    }
                </TradecordSelect>
                <br/>
                <TradecordButton style={{boxShadow: "none", position:"absolute",bottom:"0",marginBottom:"20px"}} onClick = {saveTwitter}>Save</TradecordButton>
           </Box>
       </Box>
    );
}
