import React from "react";
import TwitterIcon from '@material-ui/icons/Twitter';
import {Link} from 'react-router-dom';

import logoOrange from '../../assets/images/logo-orange.png';

import socials from '../../data/socials';

import styles  from './footer.module.css';
import {DiscordIcon} from "../Icon";
import {Box} from "@material-ui/core";


/**
 * Footer is a flex display with 3 sections
 */
const Footer = () => {

    // const history = useHistory();
    //
    // const handleClickTOS = () => {
    //     history.push("/terms");
    //   }

      const getSocialIcon = (name) => {
        let Icon;
          // eslint-disable-next-line default-case
        switch(name){
            case 'twitter': {
                Icon = TwitterIcon
                break;
            }
            case 'discord': {
                Icon = DiscordIcon
                break;
            }
        }
        return <Icon htmlColor="#FF8900" />
      }


    return(
        <footer className= {styles.homeFooter}>
            <Box display="flex" justifyContent="space-between">
            <img className={styles.footerLogo} src={logoOrange} alt="Tradecord logo orange" />
                <ul className={`${styles.footerList} ${styles.socialIcons}`}>
                    {
                        socials.map((social) =>
                            <li key={social.name}><a href={social.link}>{getSocialIcon(social.name)}</a></li>
                        )
                    }

                </ul>
            </Box>
            <Box display="flex" justifyContent="space-between">
            <div className={styles.copyRightText}>
                    &copy; 2020, Tradecord LLC, all rights reserved.
                </div>
                <ul className={styles.footerList}>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/browse'>Browse</Link></li>
                    <li><Link to="/help">Help</Link></li>
                    <li><Link to='/terms'>Terms Of Service</Link></li>

                </ul>
            </Box>
        </footer>
    )

}

export default Footer;
