import React from "react";

import FeatureBlock from "../../components/FeatureBlock";

import specialFeatures from "../../data/specialFeatures";

import styles from "./home.module.css";
import {Box, Container} from "@material-ui/core";


const SpecialFeatures = () => {
    return(
        <Container className={styles.specFeaturesContainer}>
        <Box className={styles.specFeaturesDescription}>
            <span className={styles.specFeaturesTitle}>Features</span>
        </Box>
            {specialFeatures.map(feature =>  <FeatureBlock
            key={feature.id}
            img={feature.image}
            title={feature.title}
            description={feature.description}
            orientation={feature.orientation}
            buttonText = {feature.buttonText}
        />
    )}
</Container>
    )
}

export default SpecialFeatures;
