import React, {  useState } from "react";

import XButton from '@material-ui/icons/Close';
import styles from './settings.module.css';
import TradecordButton from "../../components/TradecordButton";

import axios from 'axios';





/**
 * Popup to edit bio in settings page
 */

export default function EditBio(props) {

   
    const [bio,setBio] = useState(props.bio);
    const [charCount,setCharCount] = useState(props.bio?props.bio.length:0);

    const onBioChange = (event) =>{
        setCharCount(event.target.value.length);
        setBio(event.target.value);
    }

    const onSubmit = async() =>{

        if(charCount>200)
        {
            window.alert("Please keep your bio under 200 characters.")
            return;
        }
        props.load();
        props.setCurBio(bio);
        const body = {
            bio:bio
        }

        axios.post(process.env.REACT_APP_API_ENDPOINT + 'settings/setBio' ,body,{withCredentials: true})
        .then(res => {
             props.closePopup();
             props.stopLoad(); 
        })
        .catch(err=>{
            props.stopLoad();
            window.alert(err.response.data);
        })
    }

    return (
        <div className = {styles.popup}>
            <div className = {styles.popupInner}>
            <span style={{display: 'flex', justifyContent: 'space-between'}}><h1>Edit Bio</h1><XButton
                    className ={styles.closeButton}
                    onClick={props.closePopup}
                    fontSize="large"
                /></span>
                <textarea
                  className={styles.bioTextArea}
                  placeholder="Enter your bio. This will be visible to other users when you create a listing."
                  onChange={onBioChange}
                >{props.bio}</textarea>
                <TradecordButton  style={{boxShadow: "none"}} variant="filled" onClick = {async()=>{onSubmit()}}>Save</TradecordButton>
                <span className={charCount> 200? styles.charCountInvalid : styles.charCount}>{charCount}/200</span>
            </div>
        </div>
    );
}
