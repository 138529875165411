import React from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
 
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #FF8E00;
`;
 
/**
 * Spinner to display before api calls have fetched
 */
export default class Spinner extends React.Component {
 
 
  render() {
    return (
      <div>
        <ClipLoader
          css={override}
          size={100}
          color={"#FF8E00"}
          loading={true}
        />
      </div>
    );
  }
}