import React from 'react'
import SvgIcon from "@material-ui/core/SvgIcon";

const DiscordIcon = (props) => {
    return(
        <SvgIcon {...props} viewBox="0 0 21 12">
            <path d="M17.485,1.779A8.427,8.427,0,0,0,12.7,0l-.24.273a11.218,11.218,0,0,1,4.26,2.154A14.214,14.214,0,0,0,11.574.8,14.434,14.434,0,0,0,8.121.838a1.714,1.714,0,0,0-.29.033A13.4,13.4,0,0,0,3.95,1.949c-.593.256-.961.452-1,.475A11.218,11.218,0,0,1,7.419.2L7.248,0A8.467,8.467,0,0,0,2.462,1.778,22.591,22.591,0,0,0,0,11.658,6.209,6.209,0,0,0,5.214,14.24s.632-.748,1.145-1.4a5.339,5.339,0,0,1-2.988-2s.171.119.478.29c.018,0,.035.016.069.034.051.034.1.052.153.085a10,10,0,0,0,1.248.582,13.029,13.029,0,0,0,2.515.736,11.971,11.971,0,0,0,4.427.016,12.2,12.2,0,0,0,2.478-.735,9.841,9.841,0,0,0,1.96-1.009,5.42,5.42,0,0,1-3.092,2.017c.512.631,1.128,1.367,1.128,1.367v0a6.269,6.269,0,0,0,5.214-2.565,22.587,22.587,0,0,0-2.462-9.881M8.521,8.33A1.886,1.886,0,1,1,6.783,6.307H6.8a1.808,1.808,0,0,1,1.73,1.88c0,.048,0,.094-.006.143m6.24,0a1.885,1.885,0,1,1-1.739-2.024h.015a1.805,1.805,0,0,1,1.729,1.88c0,.048,0,.094,0,.143"
                 />

        </SvgIcon>
    )
}

export default DiscordIcon;
