import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import styles from './membership.module.css';
import axios from 'axios';

/**
 * See https://stripe.com/docs/billing/subscriptions/fixed-price
 */

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const updating = props.update;
  const elements = useElements();
  const customerId = props.user.stripeCustomerId;
  const priceId = process.env.REACT_APP_PRICE;
  const [subscribing, setSubscribing] = useState(false);
  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [discount,setDiscount] = useState('');
  const [referral,setReferral] = useState(props.referralCode ? props.referralCode : '');
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
   
    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    );
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (error) {
      //console.log('[createPaymentMethod error]', error);
      setSubscribing(false);
      setErrorToDisplay(error && error.message);
      return;
    } else {
      //console.log('[PaymentMethod]', paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem('latestInvoiceId');
        retryInvoiceWithNewPaymentMethod({
          customerId,
          paymentMethodId,
          invoiceId,
          priceId,
        });
      } else {
        // Create the subscription
        if(updating) updateSubscription({ customerId, paymentMethodId });
        else createSubscription({ customerId, paymentMethodId, priceId });
      }
    }
  };

function updateSubscription({ customerId, paymentMethodId }){
  return (
    fetch(process.env.REACT_APP_API_ENDPOINT +'stripe/update-payment', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        subscriptionId: props.subId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionCompleteUpdate)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        setSubscribing(false);
        setErrorToDisplay(error.message || error.error.decline_code);
      })
  );

}

   //step 7
   function onSubscriptionCompleteUpdate(result) {
  // Payment was successful.
  if (result && !result.subscription) {
    const subscription = { id: result.invoice.subscription };
    result.subscription = subscription;
    localStorage.removeItem('latestInvoicePaymentIntentStatus');
    localStorage.removeItem('latestInvoiceId');
  }
  
      localStorage.removeItem('latestInvoicePaymentIntentStatus');
      localStorage.removeItem('latestInvoiceId');
      axios.post(process.env.REACT_APP_API_ENDPOINT + 'discord/update-subscription',{subId:result.subscription.id,paymentMethodId:result.paymentMethodId},{withCredentials: true})
      .then(res => {
          props.fetchAccountInfo();
      })
      .catch(err=>{
        window.alert(err.response.data);
      })
    // Change your UI to show a success message to your customer.
    // Call your backend to grant access to your service based on
    // `result.subscription.items.data[0].price.product` the customer subscribed to.

}
  
//step 7 ----------------------------------------------------------------------------
function createSubscription({ customerId, paymentMethodId, priceId }) {
    return (
      fetch(process.env.REACT_APP_API_ENDPOINT +'stripe/create-subscription', {
        method: 'post',
        credentials: "include",
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          coupon:discount,
          referral:referral
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false);
          setErrorToDisplay(error.message || error.error.message ||error.error.decline_code);
        })
    );
  }

  //step 7
  function onSubscriptionComplete(result) {
    // Payment was successful.
      if (result && !result.subscription) {
        const subscription = { id: result.invoice.subscription };
        result.subscription = subscription;
        localStorage.removeItem('latestInvoicePaymentIntentStatus');
        localStorage.removeItem('latestInvoiceId');
      }
        
        axios.post(process.env.REACT_APP_API_ENDPOINT + 'discord/subscribe',{
          subId:result.subscription.id,
          paymentMethodId:result.paymentMethodId,
          resubscribe:props.resubscribe,
          referral: referral},
          {withCredentials: true})
        .then(res => {
            localStorage.setItem('role',res.data.role);
            props.fetchAccountInfo();
        })
        .catch(err=>{
          window.alert(err.response.data);
        })
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
    
  }



  

  //step 8 ---------------------------------------------------------------------------------------------------------------
  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }
  
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
  
    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId,invoice };
    }
  }

  //step 9 --------------------------------------------------------------------------------------
  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw new Error('Your card was declined.');
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  //step 9 -----------------------------------------------------------------------
  function retryInvoiceWithNewPaymentMethod({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId
  }) {
    return (
      fetch(process.env.REACT_APP_API_ENDPOINT + 'stripe/retry-invoice', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false);
          setErrorToDisplay(error && error.error && error.error.decline_code);
        })
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      <div className = {styles.stripeError}>{errorToDisplay}</div>
      {updating?
        <button className = {styles.checkOutButton} disabled={!stripe}>{subscribing ? 'Updating...' : 'Update'}</button>
      :
        <div>
          <input disabled={subscribing} className = {styles.discountCode}type="text" placeholder="Discount Code" onChange={e=>setDiscount(e.target.value)}></input>
          <input disabled={subscribing} className = {styles.discountCode}type="text" defaultValue={props.referralCode} placeholder="Referral Code" onChange={e=>setReferral(e.target.value)}></input>
          <button className = {styles.checkOutButton} disabled={!stripe || subscribing}>{subscribing ? 'Subscribing...' : 'Subscribe'}</button>
        </div>
      }
    </form>
  );
}

