import React, { Component } from 'react';

import { AppRouter } from './AppRouter';
import { ToastContainer, Flip } from 'react-toastify';
import { UserProvider } from '../../context/UserContext';
import XButton from '@material-ui/icons/Close';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../toast/toast.module.css';

const CloseButton = ({ closeToast }) => (
  <i
    onClick={closeToast}
  >
  <XButton/>
  </i>
);


class App extends Component {

  
  render() {
    return (
      <UserProvider>
        <AppRouter />
        <ToastContainer
                autoClose={1800}
                closeButton={CloseButton}
                transition={Flip}
                toastClassName = {styles.toast}
                bodyClassName = {styles.toastBody}
                progressClassName = {styles.progressBar}
                pauseOnHover = {false} />
      </UserProvider>
    );
  }
}

export default App;
