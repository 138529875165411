import React from 'react';
import {RenderImage} from "../../../components/Shared/Utility";


import styles from './productCard.module.css';

const ProductCard = (props) => {
    const {listing, onClick} = props;

    const handleOnClick = () => {
        onClick(listing);
    }

    return(
        <div
            className={styles.cardWrapper}
             onClick={handleOnClick}>
            <img className={styles.cardImage} alt={`${listing.subcategory}`}src={RenderImage(listing.subcategory,listing.twitterURL)} />
            <div className={styles.cardDetails}>
                <span className={styles.cardTitle}>{listing.title}</span>
                <span className={styles.cardPrice}>{listing.price}</span>
            </div>
        </div>
    )
}

export default ProductCard
