import React, {  useState, useEffect } from "react";
import axios from 'axios';
import Spinner from '../../../components/Shared/Spinner';
import ProductCard from "../ProductCard/ProductCard";

import styles from './product-display.module.css';
import ProductInfo from "../ProductInfo/ProductInfo";
import TradecordButton from "../../../components/TradecordButton";



/**
 * Loads the appropriate listings in the browse route
 * Takes into account the search bar, the category, and the bot
 */

function getHeader(category,subcategory,search,listingId)
{
    if(search)
        return "Results for '" + search + "'";
    else if(listingId)
        return "Direct Link";
    else{
        var result = "" + category;
        if(subcategory !== "")
            result = result + " - " + subcategory;
        return result;
    }
}

//Creates url from the category and bot
function getURLFromFilter( category, subcategory,page,premium,twitter_connected,lifetime,renewal)
{
    var string = process.env.REACT_APP_API_ENDPOINT + 'listings/retrieveAll'
    string = string + '?category=' +  encodeURIComponent(category.trim());
    if(subcategory.trim() !== "")
        string = string + '&subcategory=' + encodeURIComponent(subcategory.trim())
    string = string + '&page=' + page;
    if(premium)
        string = string + `&premium=true`
    if(twitter_connected)
        string = string + `&twitter=true`
    if(renewal)
        string = string + '&renewal=true'
    if(lifetime)
        string = string + '&lifetime=true'
    return string
}

//Creates url from the search bar query
function getURLFromSearch( search,page,premium,twitter_connected,lifetime,renewal)
{
    var string = process.env.REACT_APP_API_ENDPOINT + 'listings/search/';
    string = string + '?search=' +  encodeURIComponent(search.trim());
    string = string + '&page=' + page;
    if(premium)
        string = string + `&premium=true`
    if(twitter_connected)
        string = string + `&twitter=true`
    if(renewal)
        string = string + '&renewal=true'
    if(lifetime)
        string = string + '&lifetime=true'
    return string;
}

export default function ProductDisplay(props) {

    const listingLimit = 50; //max number of listings to display
    const[listings, setListings] = useState([])
    const[curPage, setPage] = useState(1)
    const[hasMore,setHasMore] = useState(true)
    const[loading,setLoading] = useState(true)
    const[isOpen, setIsOpen] = useState(false)
    const[selectedListing, setSelected] = useState({})

    useEffect(()=>{
        if(props.isMounted){
            window.scrollTo(0, 0);
            setPage(1);
            setHasMore(true);
            setLoading(true);
            setListings([]);
            if(props.displayStatus.search) //If there is an active search query, retrieve that
            {
                async function getData(){
                    try{
                        axios.get(getURLFromSearch(props.displayStatus.search,1,props.displayStatus.premium,props.displayStatus.twitter_connected,props.displayStatus.lifetime,props.displayStatus.renewal),{withCredentials: true})
                            .then(res => {
                                if(listingLimit > res.data.length )
                                    setHasMore(false);
                                else
                                    setPage(2);
                                setLoading(false);
                                setListings(res.data)
                            })
                            .catch(err=>{
                                window.alert(err.response.data);
                            })
                    }
                    catch(err)
                    {
                        if(err.response.status === 401 || err.response.status === 403)
                            window.alert('Access Denied')
                        if(err.response.status === 503)
                            window.alert('The server is currently experiencing too much traffic. Try again later')
                    }
                }
                getData();
            }
            else if(props.displayStatus.listingId){ //if searching by listing id
                async function getData(){
                    try{
                        axios.get(process.env.REACT_APP_API_ENDPOINT + 'listings/findById/' + props.displayStatus.listingId,{withCredentials: true})
                        .then(res => {
                            if(listingLimit > res.data.length )
                                setHasMore(false);
                            else
                                setPage(2);
                            setLoading(false);
                            setListings(res.data)
                        })
                        .catch(err=>{
                            window.alert(err.response.data);
                        })
                        }
                    catch(err)
                    {
                        window.alert(err.response.data);
                    }
                }
                getData();
            }
            else{ //else retrieve the query for the selected filters
                async function getData(){
                    try{
                        axios.get(getURLFromFilter(props.displayStatus.category,props.displayStatus.subcategory,1,props.displayStatus.premium,props.displayStatus.twitter_connected,props.displayStatus.lifetime,props.displayStatus.renewal),{withCredentials: true})
                        .then(res => {
                            if(listingLimit > res.data.length )
                                setHasMore(false);
                            else
                                setPage(2);
                            setLoading(false);
                            setListings(res.data)
                        })
                        .catch(err=>{
                            window.alert(err.response.data);
                        })
                        }
                    catch(err)
                    {
                        window.alert(err.response.data);
                    }
                }
                getData();
            }
        }
    // eslint-disable-next-line
    },[props.displayStatus,props.isMounted])

    const togglePopUp = () => {
        setIsOpen(!isOpen)
      };

    const selectListing = (listing) => {
        setSelected(listing)
    };

    const fetchMore = async()=>{
        if(props.displayStatus.search) //If there is an active search query, retrieve that
        {
                async function getData(){
                    try{
                        axios.get(getURLFromSearch(props.displayStatus.search,curPage,props.displayStatus.premium,props.displayStatus.twitter_connected,props.displayStatus.lifetime,props.displayStatus.renewal),{withCredentials: true})
                            .then(res => {
                                if(listingLimit > res.data.length )
                                    setHasMore(false);
                                else
                                    setPage(curPage + 1);
                                setListings(listings.concat(res.data))
                            })
                            .catch(err=>{
                                window.alert(err.response.data);
                            })
                    }
                    catch(err)
                    {
                        if(err.response.status === 401 || err.response.status === 403)
                            window.alert('Access Denied')
                        if(err.response.status === 503)
                            window.alert('The server is currently experiencing too much traffic. Try again later')
                    }
                }
                getData();
            }
            else{ //else retrieve the query for the selected filters
                async function getData(){
                    try{
                        axios.get(getURLFromFilter(props.displayStatus.category,props.displayStatus.subcategory,curPage,props.displayStatus.premium,props.displayStatus.twitter_connected,props.displayStatus.lifetime,props.displayStatus.renewal),{withCredentials: true})
                        .then(res => {
                            if(listingLimit > res.data.length )
                                setHasMore(false);
                            else
                                setPage(curPage + 1);
                            setListings(listings.concat(res.data))
                        })
                        .catch(err=>{
                            window.alert(err.response.data);
                        })
                        }
                        catch(err)
                        {
                            if(err.response.status === 401 || err.response.status === 403)
                                window.alert('Access Denied')
                            if(err.response.status === 503)
                                window.alert('The server is currently experiencing too much traffic. Try again later')
                        }
                    }
                    getData();
            }
    }

    const handleProductClick = (listing) => {
        togglePopUp();
        selectListing(listing);
    }
    

    return(
        <div className={!props.isDesktop ? styles.mobileProductContainer : styles.productContainer}>
            <h1><span className={props.isDesktop?styles.title:styles.mobileTitle}>{`${getHeader(props.displayStatus.category,props.displayStatus.subcategory,props.displayStatus.search,props.displayStatus.listingId)}`}</span></h1>
            {loading ? <div className = {!props.isDesktop ? styles.spinnerMobile : styles.spinner}><Spinner /></div> : listings.length === 0 && !loading ? <h1>No results</h1> : null}
          
            <div className = {styles.products}>
            {
                listings.map(listing =>
                    <ProductCard
                        key ={listing._id}
                        onClick={handleProductClick}
                        listing={listing}
                    />
               )
            }


            </div>
            {hasMore && !loading ?
                <div className = {styles.loadMoreContainer}>
                    <TradecordButton 
                        style={{boxShadow: "none",width:'200px'}}
                        onClick = {()=>{fetchMore()}}>
                        Load More
                    </TradecordButton>
                </div> : null
            }
            {isOpen ?  
                <ProductInfo  productInfo = {selectedListing}
                    closePopup={togglePopUp.bind(this)}   
                    isDesktop = {props.isDesktop} 
                    loggedIn = {props.loggedIn}
                    sendDM = {props.sendDM}
                    deleteListing = {props.deleteListing}
                    copyToClipboard = {props.copyToClipboard}
                />  
                : null  
            }
        </div>
    )

}
