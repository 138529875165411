import React, {useEffect, useState } from "react";
import axios from 'axios';
import {Box, Grid} from "@material-ui/core";


import Navbar from "../../components/Navbar"
import {Footer, MobileFooter} from "../../components/Footer";
import Spinner from '../../components/Shared/Spinner';
import TwitterAccount from './TwitterAccount';
import Webhooks from './Webhooks';
import PaymentMethods from './PaymentMethods';
import UserLogIn from '../../components/Shared/UserLogIn';
import CheckIfDesktop from "../../components/Shared/CheckIfDesktop";
import EditBio from "./EditBio";

import styles from './settings.module.css';
import {BREAKPOINTS} from "../../utilities/constants";

/**
 * The parent component for the Settings page
 * Uses CSS grid to position components
 * All the settings are fetched in fetchUserSettings so that only 1 API call is required
 *  
 */

export default function Settings(){

  // eslint-disable-next-line
  const [isMounted,setIsMounted] = useState(false)
  const isDesktop = CheckIfDesktop(500,setIsMounted);
  const user = UserLogIn(setIsMounted);
  const [loading,setLoading]= useState(true);
  const [webhooks,setWebhooks] = useState([]);
  const [curSelectedTwitter,setCurSelectedTwitter] = useState(user.selectedTwitter);
  const [paymentMethods,setPaymentMethods] = useState(user.paymentMethods);
  const [fetchCount,setFetchCount] = useState(0);
  const [editBio,setEditBio] = useState(false);
  const [bio,setBio] = useState(user.bio);


  useEffect(()=>{
    if(user.isLoggedIn && fetchCount === 0){
      setPaymentMethods(user.paymentMethods);
      setCurSelectedTwitter(user.selectedTwitter)
      setCurBio(user.bio)
      fetchUserSettings();
      setFetchCount(f=>f + 1);
    }
  },[user,fetchCount])


  const fetchUserSettings = () => {
    axios.get(process.env.REACT_APP_API_ENDPOINT+'settings/userSettings',{withCredentials: true})
    .then(res=>{
      setWebhooks(res.data.webhooks);
      setLoading(false)
    })
    .catch(err=>{
      window.alert(err.response.data);
    })
  }

  //So that payment methods are saved on reload
  const savePaymentMethods = (paymentMethods) =>{
    setPaymentMethods(paymentMethods);
  }

  //So that twitter is saved on reload
  const setCurTwitter= (twitter) =>{
    setCurSelectedTwitter(twitter);
  }

  const setCurBio = (newBio)=>{
    setBio(newBio);
  }

  const load = () =>{
    setLoading(true);
  }

  const stopLoad = () =>{
    setLoading(false);
  }

  const toggleBio = () =>{
    setEditBio(!editBio);
  }
  
  
    return (
        <div>
            <div className = {styles.settings}>
              <Navbar
                onBrowse = {false}
                isDesktop = {isDesktop}
                user = {user}
                onHome = {false}
              />
              {window.innerWidth < 1184 ?
              <div>
                {loading? <div className = {!isDesktop ? styles.spinnerMobile : styles.spinner}><Spinner /></div>  :
                <Box padding="25px">
                  <h1 style={{color: 'white', fontSize: '48px'}}>Settings</h1>
                  <Box>
                      <Webhooks
                          role = {user.role}
                          webhooks = {webhooks}
                          fetchWebhooks = {fetchUserSettings}
                          stopLoad = {stopLoad}
                          load = {load}
                          isDesktop = {isDesktop}
                      />
                  </Box>
                  <Box mt={3.75}>
                      <PaymentMethods
                          load = {load}
                          stopLoad = {stopLoad}
                          paymentMethods = {paymentMethods}
                          savePaymentMethods = {savePaymentMethods}
                          isDesktop = {isDesktop}
                      />
                  </Box>
                  <Box>
                              <TwitterAccount
                                  user = {user}
                                  curTwitter = {curSelectedTwitter}
                                  load = {load}
                                  stopLoad = {stopLoad}
                                  setCurTwitter = {setCurTwitter}
                                  isDesktop = {isDesktop}
                              />
                      </Box>
                  <Box className={styles.settingsBox}>
                      <span className={styles.titleText}>Middleman Credit</span>
                      <span style={{color: '#FFB300', fontSize: '32px'}}>$15.65</span>
                  </Box>

                          <Box className={styles.settingsBox} mt={3.75}>
                              <Box display="flex" justifyContent="space-between">
                                  <span className={styles.titleText}>Bio</span>
                                  <span className={styles.bodyText} style={{color: '#FFB300'}} onClick={() => setEditBio(true)}>Edit</span>
                              </Box>
                              <p className={`${styles.bodyText} ${styles.bioText}`}>
                                {bio?bio:"Click edit to add a bio."}
                              </p>
                          </Box>
              </Box>
              }
              </div>
              :
              <div>
                {loading? <div className = {!isDesktop ? styles.spinnerMobile : styles.spinner}><Spinner /></div>  :
                    <Box padding="60px">
                      <h1 className={styles.titleText}>Settings</h1>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Box display="flex" flexDirection="column" height="100%">
                            <TwitterAccount
                                user = {user}
                                curTwitter = {curSelectedTwitter}
                                load = {load}
                                stopLoad = {stopLoad}
                                setCurTwitter = {setCurTwitter}
                                isDesktop = {isDesktop}
                            />
                            <Box className={styles.settingsBox}>
                              <span className={styles.titleText}>Middleman Credit</span>
                              <span style={{color: '#FFB300', fontSize: '32px'}}>{user.credit}</span>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <PaymentMethods
                          load = {load}
                          stopLoad = {stopLoad}
                          paymentMethods = {paymentMethods}
                          savePaymentMethods = {savePaymentMethods}
                          isDesktop = {isDesktop}
                          />

                            <Box className={styles.settingsBox}>
                              <Box display="flex" justifyContent="space-between">
                                <span className={styles.titleText}>Bio</span>
                                <span className={styles.bodyText} style={{color: '#FFB300'}} onClick={() => setEditBio(true)}><span className = {styles.clickable}>Edit</span></span>
                              </Box>
                              <p className={`${styles.bodyText} ${styles.bioText}`}>
                                {bio?bio:"Click edit to add a bio."}
                              </p>
                            </Box>

                        </Grid>
                        <Grid item xs={5}>
                          <Box  display="flex" flexDirection="column" height="100%">
                          <Webhooks
                              role = {user.role}
                              webhooks = {webhooks}
                              fetchWebhooks = {fetchUserSettings}
                              stopLoad = {stopLoad}
                              load = {load}
                              isDesktop = {isDesktop}
                          />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                }
                </div>
              }
            </div>
            {editBio?<EditBio 
              closePopup = {toggleBio} 
              bio = {bio}
              load = {load}
              stopLoad = {stopLoad}
              setCurBio = {setCurBio}
              />: null }
            {window.innerWidth < BREAKPOINTS.MD ? <MobileFooter/> : <Footer />}

        </div>
    );
  }
