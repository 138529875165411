import { useContext,useEffect } from 'react';
import {UserContext} from '../../context/UserContext';

const UserLogIn = (setIsMounted) =>{

    const [user,setUser] = useContext(UserContext);

    useEffect(()=>{
        const fetchData = () =>{
        fetch(process.env.REACT_APP_API_ENDPOINT+"auth/login/success", {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true
          }
          })
          .then(response => {
            if (response.status === 200) return response.json();
            throw new Error("failed to authenticate user");
          })
          .then(responseJson => {
            const tempPaymentMethods = [];
            responseJson.user.paymentMethods.forEach(function(method){
              tempPaymentMethods.push(method.name);
            })
            setUser({
                stripeCustomerId: responseJson.user.stripeCustomerId,
                username: responseJson.user.username,
                discordId: responseJson.user.discordId,
                role: responseJson.user.role,
                discriminator: responseJson.user.discriminator,
                avatar: responseJson.user.avatar,
                isLoggedIn: true,
                twitterAccounts: responseJson.user.twitterAccounts,
                rating: responseJson.user.rating,
                ratingCount: responseJson.user.ratingCount,
                credit: `$${responseJson.user.credit.$numberDecimal}`,
                paymentMethods: tempPaymentMethods,
                selectedTwitter: responseJson.user.selectedTwitter,
                bio: responseJson.user.bio
            });
            localStorage.setItem('username',responseJson.user.username);
            localStorage.setItem('discordId',responseJson.user.discordId);  
            localStorage.setItem('role',responseJson.user.role);  
            localStorage.setItem('discriminator',responseJson.user.discriminator);  
            localStorage.setItem('avatar',responseJson.user.avatar);  
            localStorage.setItem('isLoggedIn',true);
            
            setIsMounted(true);
          })
          .catch(error => {
            setUser({
                stripeCustomerId:'',
                username:'',
                discordId:'',
                role:0,
                discriminator:'',
                avatar:'',
                isLoggedIn:false,
                twitterAccounts:[],
                rating:0,
                ratingCount:0,
                credit:0,
                paymentMethods:[],
                selectedTwitter:'',
                bio:''
            })
            localStorage.clear();
            setIsMounted(true);
          });
          
      }
      fetchData()
    },[setUser,setIsMounted])
    return user;
}

export default UserLogIn;