import React, { useState } from "react";
import { toast } from 'react-toastify';

import ListingManager from './ListingManager';
import MobileListingManager from './MobileListingManager';
import ManageListingSettings from './ManageListingSettings';
import Navbar from "../../components/Navbar";
import {Footer, MobileFooter} from "../../components/Footer";
import UserLogIn from '../../components/Shared/UserLogIn';
import CheckIfDesktop from '../../components/Shared/CheckIfDesktop';
import styles from './manage.module.css';
import {BREAKPOINTS} from "../../utilities/constants";
import TradecordSelect from "../../components/TradecordSelect";

import {
  MenuItem,
} from '@material-ui/core';




export default function Manage() {

  const [displayStatus,setDisplayStatus] = useState({
    subcategory:"Active",
    manageListings: true,
    refreshListings: false
  });

  const [isMounted,setIsMounted] = useState(false);
  const isDesktop = CheckIfDesktop(500,setIsMounted);
  const user = UserLogIn(setIsMounted);

  const setSubCategory = (subcategory) =>{
      setDisplayStatus({
        ...displayStatus,
        subcategory: subcategory
      })
  }

  const archiveListing = () => {
    setDisplayStatus({
      ...displayStatus,
      refreshListings:!displayStatus.refreshListings
    })
    toast("Listing Archived!");
  }

  const deleteListing = () => {
    setDisplayStatus({
      ...displayStatus,
      refreshListings:!displayStatus.refreshListings
    })
    toast("Listing Deleted!"); 
  }

  const relistListing = () => {
    setDisplayStatus({
      ...displayStatus,
      refreshListings:!displayStatus.refreshListings
    })
    toast("Listing Relisted!");
  }

  const editListing = () => {
    setDisplayStatus({
      ...displayStatus,
      refreshListings:!displayStatus.refreshListings
    })
    toast("Changes Confirmed!");
  }

 
  function renderFilterArea(){
    if(BREAKPOINTS.MD < window.innerWidth){
      return  <ManageListingSettings 
                displayStatus = {displayStatus} 
                setSubCategory ={setSubCategory}
              />
    }
    else{
      return <TradecordSelect
            fullWidth = {false}
            css = {styles.subCatButton}
            value = {displayStatus.subcategory}
            onChange={(e)=>setSubCategory(e.target.value)}>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Archived">Archived</MenuItem>
            </TradecordSelect>
    }
  }

  function renderContentArea(){
      if(BREAKPOINTS.MD < window.innerWidth){
        return <ListingManager 
          isDesktop = {isDesktop}  
          archive = {archiveListing} 
          delete = {deleteListing} 
          relist = {relistListing} 
          displayStatus = {displayStatus}
          isMounted = {isMounted} 
          editListing = {editListing}          
          discordId = {user.discordId}/>
      }
      else{
        return <MobileListingManager 
          isDesktop = {isDesktop} 
          archive = {archiveListing} 
          delete = {deleteListing} 
          relist = {relistListing} 
          displayStatus = {displayStatus} 
          isMounted = {isMounted}
          editListing = {editListing}       
          discordId = {user.discordId}/>
      }
  }

    return (
        <div>
          
            <div className = {styles.dashboard}>
                <Navbar
                   onBrowse = {false}
                   isDesktop = {isDesktop}
                   user = {user}
                   onHome = {false}
                  />
                  <div className = { BREAKPOINTS.MD < window.innerWidth ? styles.dashWrapper : null}>
                  {renderFilterArea()}
                  {renderContentArea()}
                  </div>
            </div>
            {window.innerWidth < BREAKPOINTS.MD ? <MobileFooter/> : <Footer />}

      </div>
    );
}
