import React, {useState} from "react";

import Header from "../../components/Header";
import {Footer, MobileFooter} from "../../components/Footer";
import Navbar from '../../components/Navbar';
import UserLogIn from '../../components/Shared/UserLogIn';
import CheckIfDesktop from '../../components/Shared/CheckIfDesktop';
import SpecialFeatures from "./SpecialFeatures";
import NewsLetter from "./NewsLetter";

import styles from './home.module.css';
import SitesSection from "./SitesSection";
import {BREAKPOINTS} from "../../utilities/constants";



/**
 * Parent component for landing page
 * Checks to see if user is logged in on initial load
 */

export default function HomePage(){

  // eslint-disable-next-line
  const [isMounted,setIsMounted] = useState(false)
  const isDesktop = CheckIfDesktop(500,setIsMounted);
  const user = UserLogIn(setIsMounted);

  return (
    <React.Fragment>
      <Navbar
        onBrowse = {false}
        onHome = {true}
        isDesktop = {isDesktop}
        user = {user}
      />
      <Header/>
        <div className={styles.secondaryContent}>
           <SitesSection />
           <SpecialFeatures />
           <NewsLetter/>
        </div>
        {window.innerWidth < BREAKPOINTS.MD ? <MobileFooter/> : <Footer />}
    </React.Fragment>
  );
}

