import React from 'react';
import {Box, Button, IconButton, Menu, MenuItem} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {RenderImage} from '../Shared/Utility';

import styles from './listing.module.css';
import {BREAKPOINTS} from "../../utilities/constants";


const Listing = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return(
        <Box className={styles.listingWrapper} display="flex" justifyContent="space-between" alignItems="center">
            <img className={styles.listingImage} alt="Bot" src={RenderImage(props.post.subcategory,props.post.twitterURL)} />
            <span className={styles.listingPrice}>{props.post.price}</span>
            <span className={styles.listingTitle}>{props.post.title}</span>
            <Box className={styles.listingActionsContainer}>
                {window.innerWidth <= BREAKPOINTS.XL ? <><IconButton onClick={handleClick} aria-controls="listing-actions">
                    <MoreVertIcon className={styles.moreButton} aria-haspopup="true"/>
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableScrollLock={true}
                >
                    {props.displayStatus.subcategory === "Archived" ? <div>
                    <MenuItem onClick = {() => {props.relistListing(props.post._id)
                                                handleClose()}}>Relist</MenuItem>
                    <MenuItem onClick = {() => {props.deleteListing(props.post._id)
                                                 handleClose()}}>Delete</MenuItem>
                    </div> :
                    <div>
                    <MenuItem onClick = {() => {props.handleEdit(props.post)
                                                handleClose()}}>Edit</MenuItem>
                    <MenuItem onClick = {() => {props.archiveListing(props.post._id)
                                                handleClose()}}>Archive</MenuItem>
                    </div>}
                </Menu></> : <>
                {props.displayStatus.subcategory === "Archived" ?
                <div>
                    <Button onClick = {() => props.relistListing(props.post._id)}>Relist</Button>
                    <Button onClick = {() => props.deleteListing(props.post._id)}>Delete</Button>
                </div> :
                <div>
                    <Button onClick = {() => props.handleEdit(props.post)}>Edit</Button>
                    <Button onClick = {() => props.archiveListing(props.post._id)}>Archive</Button>
                </div>
                }
                </>}
            </Box>
        </Box>
    );
}

export default Listing;
