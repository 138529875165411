import React, {  useState, useEffect } from "react";
import axios from 'axios';
import XButton from '@material-ui/icons/Close';

import ListingDescription from '../ListingDescription';
import {UserProfile, MobileUserProfile} from '../UserProfile';

import styles from './product-info.module.css';
import {BREAKPOINTS} from "../../../utilities/constants";

import {CopyToClipboard} from 'react-copy-to-clipboard';


/**
 * When a listing is clicked on, this is the  popup info that is displayed
 * If the user is logged in, then they can send DM's
 * Mods see a red button that says delete and can delete any listing.
 */




export default function ProductInfo(props) {

    const [onListing,setOnListing] = useState(true);
    const [userProfile,setUserProfile] = useState({
        username:'Loading...',
        discordId:'',
        discriminator:'',
        avatar:'',
        rating:'Loading...',
        paymentMethods:[],
        selectedTwitter:'Loading...',
        createdAt:'0-0-0000',
        bio:'Loading...'
    })
    const isStaff = localStorage.getItem('role') > 3;


    //Load the users rating
    useEffect(() => {
        const fetchData = async () => {
            axios.get(process.env.REACT_APP_API_ENDPOINT+"profile/" + props.productInfo.userId,{withCredentials: true})
            .then(res => {
                const tempPaymentMethods = [];
                res.data.paymentMethods.forEach(function(method){
                    tempPaymentMethods.push(method.name);
                })
                setUserProfile({
                    username:res.data.username,
                    discordId:res.data.discordId,
                    discriminator:res.data.discriminator,
                    avatar:res.data.avatar,
                    rating:res.data.rating,
                    paymentMethods:tempPaymentMethods,
                    selectedTwitter:res.data.selectedTwitter,
                    createdAt:res.data.createdAt,
                    bio:res.data.bio
                })
            })
            .catch(err=>{
                window.alert(err.response.data);
            })
        };
        fetchData();
      }, [props.productInfo.userId]);


    //Mods delete a listing  
    const onDelete = async() =>{
        axios.delete(process.env.REACT_APP_API_ENDPOINT + 'listings/modDelete/'+ props.productInfo._id ,{withCredentials: true})
        .then(res => {
            props.deleteListing();
            props.closePopup();    
        })
        .catch(err=>{
            window.alert(err.response.data);
        })
    }

    


    return(
        <div className = {styles.popup}>
            <div className = {props.isDesktop ? styles.popupInner : styles.popupInnerMobile}>
         


                <span className={styles.productInfoTitle} style={{display: 'flex', justifyContent: 'space-between'}}><CopyToClipboard  onCopy={props.copyToClipboard} text={`https://www.tradecord.io/browse?listing=${props.productInfo._id}`}>
                <h1 style={{cursor:"pointer"}}>{props.productInfo.title}</h1>
        </CopyToClipboard><XButton
                    className ={styles.closeButton}
                    onClick={props.closePopup}
                    fontSize="large"
                /></span>

                <ul className = {styles.popUpSectionSelect}>
                    <li
                        onClick = {()=>{setOnListing(true)}}
                    >
                        <span className={`${styles.listingTabs} ${onListing ?styles.listingTabsActive:null}`}>
                            Description
                        </span>
                    </li>
                    <li
                        onClick = {()=>{setOnListing(false)}}>
                        <span className={`${styles.listingTabs} ${!onListing ?styles.listingTabsActive:null}`}>
                            User Profile
                        </span>
                    </li>
                    {isStaff ? <li><button  className={styles.deleteButtonMods} onClick = {async()=>{onDelete()}}>Delete</button ></li> :null }   
                </ul>
                {onListing?
                    <ListingDescription
                        isStaff = {isStaff}
                        productInfo = {props.productInfo}
                        loggedIn = {props.loggedIn}
                        sendDM = {props.sendDM}
                    />
                    :

                    (BREAKPOINTS.LG < window.innerWidth) ?
                            <UserProfile
                                userProfile={userProfile}
                            /> : <MobileUserProfile
                                userProfile={userProfile}
                            />

                }    
            </div> 
        </div>
    )
}
